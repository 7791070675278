import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { login, AsterConfig } from '../redux/apicalling/dispatchfunction.js';
import { css } from '@emotion/react';
import { ClipLoader } from 'react-spinners';
import "../assets/css/loginpage.css";
import { useNavigate } from 'react-router-dom';
import { Formik, ErrorMessage } from 'formik';
import * as yup from 'yup';
import Comtemplete from './comtemplete.js';
import { toast } from 'react-toastify';
import {
  faEye,
  

} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function Login() {
  const navigate = useNavigate();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [isLoggingIn, setIsLoggingIn] = useState(false);
  const [isPasswordVisible, setIsPasswordVisible] = useState(false); // State for password visibility
  const [emailError, setEmailError] = useState('');
  const dispatch = useDispatch();
  const logingloable = localStorage.getItem('loginpage1');
  const loginlocal = localStorage.getItem('login');

  const validationSchema = yup.object({
    username: yup
      .string()
      .email('Invalid email format')
      .required('Email is required'),
    password: yup
      .string()
      .required('Password is required'),
  });

  const initialValues = {
    username: '',
    password: '',
  };

  // useEffect(() => {
  //   // Set a timeout to close the tab after a specified time (e.g., 5 seconds)
  //   const timer = setTimeout(() => {
  //     window.close();
  //   }, 5000); // 5000 milliseconds = 5 seconds

  //   // Clean up the timeout if the component unmounts before it fires
  //   return () => clearTimeout(timer);
  // }, []);


  useEffect(() => {
    if (loginlocal === "true") {
      navigate('/homepage');
    }
    
    if (logingloable == "true") {
      navigate('/queuepage')
    }

  }, []);
   
  const handleEmailChange = (value) => {
    setUsername(value);
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (value.trim() === '') {
        setEmailError('');
    } else {
        setEmailError(emailRegex.test(value) ? '' : '* Please enter the required format');
    }
}; 

  const handleLogin = () => {
    const lowerCaseUsername = username.toLowerCase();
    setIsLoggingIn(true);

    dispatch(login(lowerCaseUsername, password, 2)).then((additionalData) => {
      if (additionalData.status === 200) {
        const storedApinfo = localStorage.getItem('apinfo');
        const apiinfo = JSON.parse(storedApinfo);
        const doocti_tenent = apiinfo.tenant_code;
        setIsLoggingIn(false);

        dispatch(AsterConfig(lowerCaseUsername, doocti_tenent)).then((response) => {
          if (response.status === 200) {
            let data = JSON.stringify(response.data);
            localStorage.setItem("config", data);
            let config_user = response.data.projects[0].userInfo[0].user_email;
            localStorage.setItem('username', config_user);
            navigate('/queuepage');
          }
        }).catch((err) => {
          console.error(err);
        });
      }
    }).catch((error) => {
      setIsLoggingIn(false);
      toast.error(error);
      console.error('Login failed:', error);
    });
  };

  const togglePasswordVisibility = () => {
    setIsPasswordVisible(!isPasswordVisible);
  };

  const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
  `;

  return (
    <div>
      <Comtemplete>
        <div className="login-box">
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={() => handleLogin()}
          >
            {() => (
              <form>
                <div className="form-group">
                  <label htmlFor="username">UserEmail</label>
                  <input
                    className='logininput'
                    type="text"
                    placeholder="abcd@doocti.com"
                    value={username}
                    onChange={(e) => handleEmailChange(e.target.value)}
                      
                  />
                   <div className="error-message_">{emailError}</div>
                  </div>
                <div className="form-group">
                  <label htmlFor="password">Password</label>
                  <div className="password-input">
                    <input
                      className='logininput'
                      type={isPasswordVisible ? "text" : "password"} // Toggle input type
                      placeholder="1234"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                    />
                    
                    <span className='pwd-eyeicon' onClick={togglePasswordVisibility} style={{ cursor: 'pointer' }} >
                      {password !=="" ? <FontAwesomeIcon icon={faEye} style={{ color: '#2196F3', fontSize: '15px', }}/>:<FontAwesomeIcon icon={faEye} style={{ color: '#ccc', fontSize: '15px', }}/> } 
                    </span>
                  </div>
                </div>

                <button
                  type="button"
                  className="login-button"
                  onClick={handleLogin}
                  disabled={username === '' || password === '' || emailError}
                >
                  {isLoggingIn ? <ClipLoader color={'#ffffff'} loading={isLoggingIn} css={override} size={20} /> : 'Login'}
                </button>
              </form>
            )}
          </Formik>
        </div>
      </Comtemplete>
    </div>
  );
}

export default Login;
