const initialState = null; // Initial state

const callstatusReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_CALLSTATUS':
        return action.payload; // Set the session value
    
    default:
      return state;
  }
};

export default callstatusReducer;
