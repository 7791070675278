import React, { useState, useEffect, useRef, useCallback } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { FetchCallLog } from '../redux/apicalling/dispatchfunction';
import person from "../assets/images/person.svg"
import '../assets/css/calllogpage.css';
import { faPhone, faSearch, faMicrophone, faPhoneSlash, faBan, faCheck } from '@fortawesome/free-solid-svg-icons';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function CallLog() {
  const [contacts, setContacts] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [searchQuery, setSearchQuery] = useState('');
  const [skip, setSkip] = useState(0);
  const [take] = useState(10);
  const [hasMore, setHasMore] = useState(true);

  const observer = useRef();
  const [callDates, setCallDates] = useState([]);
  const agent = localStorage.getItem("username");
  
  const audioRef = useRef(null); // Initialize audioRef here

  const dispatch = useDispatch();
  const fetchContacts = useCallback(async (skipValue, searchTermValue) => {
    dispatch(FetchCallLog({
      agent: agent,
      skip: skipValue,
      take: take,
      phone_number: searchTermValue,
    })).then((response) => {
      if (response.status === 200) {
        const data = response.data.data;

        const formattedDates = data.map((contact) => formatDate(contact.calldate));
        setCallDates((prevCallDates) => [...prevCallDates, ...formattedDates]);

        if (data.length < take) {
          setHasMore(false);
        }

        if (skipValue === 0) {
          setContacts(data);
        } else {
          setContacts((prevContacts) => [...prevContacts, ...data]);
        }
      } else {
        setContacts([])
        setHasMore(false);
      }

    }).catch((err) => {
      return err
    })

  }, [agent, take]);

  useEffect(() => {
    fetchContacts(0, searchQuery);
  }, [fetchContacts, searchQuery]);

  useEffect(() => {
    if (skip > 0) {
      fetchContacts(skip, searchQuery);
    }
  }, [skip, fetchContacts, searchQuery]);


  const lastContactElementRef = useRef();
  const lastContactCallback = useCallback(
    (node) => {
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          setSkip((prevSkip) => prevSkip + take);
        }
      });
      if (node) observer.current.observe(node);
    },
    [hasMore, take]
  );

  
  const fetchCallRecording = (recordUrl) => {
    if (audioRef.current) {
      // If the same recording is clicked, toggle play/pause
      if (audioRef.current.src === recordUrl) {
        if (audioRef.current.paused) {
          audioRef.current.play(); // Play if paused
        } else {
          audioRef.current.pause(); // Pause if playing
          audioRef.current.currentTime = 0; // Optionally reset time when paused
        }
      } else {
        // Stop the previous audio
        audioRef.current.pause();
        audioRef.current.currentTime = 0; // Reset time

        // Set the new source for the audio element
        audioRef.current.src = recordUrl;
        audioRef.current.play();
      }
    } else {
      // Ensure the ref is correctly attached to an audio element
      audioRef.current = new Audio(recordUrl);
      audioRef.current.play();
      
      // Reset reference when playback ends
      audioRef.current.addEventListener('ended', () => {
        audioRef.current = null; // Clear reference when playback ends
      });
    }
  };

  const filteredContacts = contacts.filter((contact) =>
    contact.phone_number.includes(searchTerm)
  );

  function format(value) {
    if (typeof value !== 'string' || value.trim() === '') {
        return 'Unknown';
    }
    const fullName =  value.charAt(0).toUpperCase() + value.slice(1);
    return reduceLength(fullName);
  }

  const reduceLength = (value) => {
    if (typeof value !== 'string') {
      return "";
    }
    return value.length > 9 ? value.slice(0, 9) + '...' : value;
  };

  return (
    <div className="App">
      <div className="contact-list">
        <div className="contact-list-title"></div>
        <div className="search-container">
          <input
            className="calllogsearch"
            type="text"
            placeholder="Search Number"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </div>
  
        {filteredContacts.length === 0 ? (
          <p className="no-data-message">No Data </p>
        ) : (
          <ul className="contact-items">
            {filteredContacts.map((contact, index) => {
              const contactElement = (
                <li key={contact.id} className="contact-item">
                  <img
                    src={person}
                    alt={contact.agent}
                    className="contact-image"
                  />
                  <div className="contact-details">
                    <h5>{format(contact.name)}</h5>
                    <div className='pnumber'>{contact.phone_number}</div>
                    <div className="call-type">{callDates[index]}</div>
                    <div className='PhoneNumberWrap_rht'>
                      {contact.status === "COMPLETE" && <FontAwesomeIcon icon={faCheck} className="faCheck" />}
                      {contact.status === "ABANDON" && <FontAwesomeIcon icon={faBan} className="faBan" />}
                      {contact.status === "NOANSWER" && <FontAwesomeIcon icon={faPhoneSlash} className="faPhoneSlash" />}
                    </div>
                    <div className="icons">
                      <FontAwesomeIcon 
                        icon={faMicrophone} 
                        onClick={() => fetchCallRecording(contact.record_location)} 
                        className={contact.record_location === "" ? 'icon-disabled' : 'rec-icon'} 
                      />
                      <span className="call-duration">{contact.formatted_talk_time}</span>
                    </div>
                  </div>
                </li>
              );
  
              if (contacts.length === index + 1) {
                return <li ref={lastContactCallback} key={contact.id}>{contactElement}</li>;
              } else {
                return contactElement;
              }
            })}
          </ul>
        )}
      </div>
    </div>
  );
}

function formatDate(datestring) {
  const date = new Date(datestring); // Convert the date string to a Date object
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, '0'); // January is 0
  const day = date.getDate().toString().padStart(2, '0');
  const hours = date.getHours().toString().padStart(2, '0');
  const minutes = date.getMinutes().toString().padStart(2, '0');
  const seconds = date.getSeconds().toString().padStart(2, '0');
  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
}

export default CallLog;
