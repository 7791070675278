import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Comtemplete from "./comtemplete";
import {
  setshowcalllogpage,
  rtcended,
  rtcprocess,
} from "../redux/backgroundpermission/permission-action";
import "../assets/css/dispopage.css";
import {
  Hangup,
  FetchDispo,
  getLeadstatus,
  UpdateLeadstatus,
  rt,
  FetchCustomview,
} from "../redux/apicalling/dispatchfunction";
import { setcallstatus } from "../redux/callstatus/callstatusaction";
import { toast } from "react-toastify";
import { setcutomerinfo } from "../redux/leadinfo/customerinfoaction";
const Disposcreen = (props) => {
  let { action } = props;
  const dispatch = useDispatch();
  const [callNotes, setCallNotes] = useState("");
  const [dispositionStatus, setDispositionStatus] = useState("");
  const [dispostionstate, setDispositionState] = useState("");
  const [leadstatus, setLeadStatus] = useState("");
  const [isDispositionSelected, setIsDispositionSelected] = useState(false);
  const config_api = JSON.parse(localStorage.getItem("config"));
  const userPermission = config_api.projects[0].userPermission;
  const customer_info = useSelector((state) => state.crmcustomerinfo);
  const call_status = useSelector((state) => state.callstatus);
  const [dispositionStatusOptions, setDispositionStatusOptions] = useState("");
  const [leadstatusoptions, setLeadStatusOptions] = useState("");
  const [callPurposeOptions, setCallPurpuseOptions] = useState("");
  const [callPurpose,setCallPurpose] = useState("");

  const agent_name = localStorage.getItem("username");
  const local_customer_info = JSON.parse(localStorage.getItem("customer_info"));

  useEffect(() => {
    if(userPermission.auto_dispo == false){
      fetchDispo();
    }
    
    if(userPermission.dialer_subFilter1 && !userPermission.crm_call_purpose ){
      fetchLeadstatus();
    }
    if(userPermission.crm_call_purpose){
      fetchCallPurpuse()
    }
  }, []);

  const fetchCallPurpuse = () =>{
    dispatch(FetchCustomview()).then((response)=>{
      if(response.status === 200 ){
        setCallPurpuseOptions(response.data.data);
      }
    })
  }

  const handleCallNotesChange = (event) => {
    setCallNotes(event.target.value);
  };
  const handleDispositionStatusChange = (event) => {
    const selectedStatus = event.target.value;

    const [value, state] = selectedStatus.split(" - ");
  
    setDispositionState(state || null);
    setDispositionStatus(selectedStatus);
    setIsDispositionSelected(!!selectedStatus);
  };
  const handleleadstatusChange = (event) => {
      const selectedStatus = event.target.value;
    setLeadStatus(selectedStatus);
  };

  const fetchDispo = async () => {
    dispatch(FetchDispo(userPermission.crm_call_purpose))
      .then((response) => {
        if (response.status === 200) {
          const extractedStatuses = response.data.data;
          let dispoobjdecode = Object.values(extractedStatuses);

          const dispoarraydecode = dispoobjdecode.map((dispo, index) => {
            return dispo.label;
          });

          setDispositionStatusOptions(extractedStatuses);
          dispatch(setcallstatus(dispoarraydecode));
        }
      })
      .catch((err) => {
        return err;
      });
  };
  const fetchLeadstatus = async () => {
    dispatch(getLeadstatus())
      .then((response) => {
        if (response.status === 200) {
          const extractedleadStatus = response.data.data;
          let leadstatusobjdecode = Object.values(extractedleadStatus);

          const leadstatusarraydecode = leadstatusobjdecode.map(
            (leadstatus, index) => {
              return leadstatus.label;
            }
          );

          setLeadStatusOptions(extractedleadStatus);
          // dispatch(setcallstatus(dispoarraydecode))
        }
      })
      .catch((err) => {
        return err;
      });
  };

  const handleSubmit = () => {
    try {
      let get_callerinfo = JSON.parse(localStorage.getItem("callerinfo"));
      const callStatusValue = dispositionStatus.split(" - ")[0];
      const leadStatusValue = leadstatus.split(" - ")[0];
      const leadStateValue =leadstatus.split(" - ")[1];
      
      const payload = {
        station: config_api.projects[0].userInfo[0].user_phone_number,
        phone_number: local_customer_info.customer_number,
        //"tenant_id": tenantid,
        entity_type: local_customer_info.module,
        user_id: config_api.projects[0].userInfo[0].user_id,
        customer_id: local_customer_info.customer_id,
        agent: { identity: "user", value: agent_name },
        call_status: callStatusValue ? callStatusValue : dispositionStatus,
        call_state: dispostionstate, //this variable used for dynamic crm
        description: callNotes,
        customer_name:
          local_customer_info.customer_name !== undefined
            ? local_customer_info.customer_name
            : "Unkonwn Name",
        call_type: local_customer_info.call_type,
        lead_status: leadStatusValue ? leadStatusValue :leadstatus,
        lead_state: leadStateValue?leadStateValue:"",      //this variable used for dynamic crm
        lead_view:callPurpose !== "" ? callPurpose : local_customer_info.lead_view,
      };

      dispatch(Hangup(payload))
        .then((disporesponse) => {
          if (disporesponse.status === 200) {
            let customer_info = {
              response_status: "",
              customer_name: "",
              customer_number: "",
              module: "",
              customer_id: "",
              customer_leadstatus: "",
              customer_leadowner: "",
              call_type: "",
            };
            dispatch(setcutomerinfo(customer_info));

            localStorage.removeItem("customer_info");
            localStorage.setItem("livecall", false);
            localStorage.removeItem("callerinfo");
            localStorage.removeItem("livecall");
            if (customer_info.type == "C2C") {
              dispatch(setshowcalllogpage(true));
            } else {
              dispatch(setshowcalllogpage(false));
            }
            dispatch(setshowcalllogpage(true));
            dispatch(rtcended(false));
            dispatch(rtcprocess(true));
            const callOutcome = local_customer_info.call_outcome;
            

           
    action("dispo", callOutcome);
          
          }
        })
        .catch((err) => {
          return err;
        });

      setCallNotes("");
      setDispositionStatus("");
      setDispositionState("");
    } catch (error) {
      console.log("error", error);

      return error;
    }
  };

  const handleCallPurposeChange = (e) => {
    setCallPurpose(e.target.value)
  }

  return (
    <div>
      <Comtemplete>
        <div >
          <div>
            <div className="call-notes">
              <textarea
                rows="4"
                value={callNotes}
                onChange={handleCallNotesChange}
                placeholder="Add call notes here"
              ></textarea>
            </div>
            {userPermission.dialer_subFilter1 && !userPermission.crm_call_purpose && <div className="leadstatus-status-dropdown">
              <select
                id="dispositionStatus"
                value={leadstatus} // Store selected value in leadstatus
                onChange={handleleadstatusChange} // Update selected value on change
                className="white-select"
              >
                <option value="">
                  Select{" "}
                  {local_customer_info == null
                    ? ""
                    : local_customer_info.module}{" "}
                  Status
                </option>

                {Array.isArray(leadstatusoptions) ? (
                  leadstatusoptions.map((option, index) => {
                    const combinedValue =
                    option.state !== null &&
                    option.state !== undefined &&
                    option.state !== ""
                      ? `${option.value} - ${option.state}`
                      : option.value;
                    return (
                      <option key={index} value={combinedValue}>
                        {option.label}
                      </option>
                    );
                  })
                ) : (
                  <option value="No Data">No Data</option>
                )}
              </select>
            </div>}
            {userPermission.crm_call_purpose && <div className="leadstatus-status-dropdown">
              <select
                id="dispositionStatus"
                value={callPurpose} // Store selected value in leadstatus
                onChange={handleCallPurposeChange} // Update selected value on change
                className="white-select"
              >
                <option value="">
                  Select Call purpose
                </option>

                {Array.isArray(callPurposeOptions) ? (
                  callPurposeOptions.map((option, index) => {
                    return (
                      <option key={index} value={option.label}>
                        {option.label}
                      </option>
                    );
                  })
                ) : (
                  <option value="No Data">No Data</option>
                )}
              </select>
            </div>}

            {userPermission.dialer_subFilter2 &&<div className="disposition-status-dropdown">
              <select
                id="dispositionStatus"
                value={dispositionStatus}
                onChange={handleDispositionStatusChange}
                className="white-select"
                // required
              >
                <option value="">Select Disposition Status</option>
                {Array.isArray(dispositionStatusOptions) ? (
                  dispositionStatusOptions.map((option, index) => {
                    // Check if option.state is null, undefined, or an empty string
                    const combinedValue =
                      option.state !== null &&
                      option.state !== undefined &&
                      option.state !== ""
                        ? `${option.value} - ${option.state}`
                        : option.value;

                    return (
                      <option key={index} value={combinedValue}>
                        {option.label}
                      </option>
                    );
                  })
                ) : (
                  <option value="No Data">No Data</option>
                )}
              </select>
            </div>}
        
          </div>

          <button
            type="button"
            className="dispo-button"
            onClick={handleSubmit}
            disabled={dispositionStatus.length === 0}
          >
            Submit
          </button>
        </div>
      </Comtemplete>
    </div>
  );
};

export default Disposcreen;
