export const Autolead = (action, payload) => ({
    type: 'AUTO_DIAL_ACTION',
    payload: {action,payload}
  });
  
  export const AutoDialbtn = (payload) => ({
    type: 'AUTO_DIAL_BUTTON_STATUS',
    payload
  });
   
  export const AutoDialStatus = (payload) => ({
    type: 'AUTO_DIAL_STATUS',
    payload
  });
  
  export const AutoDialCurrent = (payload) => ({
    type: 'AUTO_DIAL_CURRENT',
    payload
  });
  export const AutoDialSubfilter1 = (payload) =>({
    type: 'AUTO_DIAL_SUBFILTER1',
    payload
  });
  export const CustomViewvalue = (payload) =>({
    type: 'CUSTOM_VIEW_VALUE',
    payload
  });
  
  export const AutoDialSubfilter2 = (payload) =>({
    type : 'AUTO_DIAL_SUBFILTER2',
    payload 
  }) 
  
  export const AutoDialPagecount = (payload) =>({
    type : 'AUTO_DIAL_PAGECOUNT',
    payload 
  }) 
  
  export const AutoDialSkipcount = (payload) =>({
    type : 'AUTO_DIAL_SKIPCOUNT',
    payload 
  }) 
  
  export const AutoDialTakecount = (payload) =>({
    type : 'AUTO_DIAL_TAKECOUNT',
    payload 
  }) 
  