import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "../assets/css/webphone.css";
import person from "../assets/images/person.svg";
import Comtemplete from "../pages/comtemplete";
import TransferConference from "../pages/transferpage";
import { FetchDispo } from "../redux/apicalling/dispatchfunction";
import { CrmUrl } from "../redux/apicalling/dispatchfunction";
import { setcallstatus } from "../redux/callstatus/callstatusaction";
import { setshowcalllogpage } from "../redux/backgroundpermission/permission-action";
import { Hangup } from "../redux/apicalling/dispatchfunction";
import {
  faEye,
  faPlus,
  faMicrophone,
  faMicrophoneSlash,
  faPhoneSlash,
  faPhone,
  faPause,
  faPlay,
  faKeyboard,
  faUsers,
  faShareSquare,
  faUserAstronaut,
  faPersonWalkingDashedLineArrowRight,
} from "@fortawesome/free-solid-svg-icons";
import { setcutomerinfo } from "../redux/leadinfo/customerinfoaction";
import { rtcended,rtcprocess } from "../redux/backgroundpermission/permission-action";
function formatCallDuration(durationInSeconds) {
  const minutes = Math.floor(durationInSeconds / 60)
    .toString()
    .padStart(2, "0");
  const seconds = (durationInSeconds % 60).toString().padStart(2, "0");
  return `${minutes}:${seconds}`;
}

function Webphone() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const session = useSelector((state) => state.session);
  const caller_insight = useSelector((state) => state.callerinsight);
  const backgroundstate = useSelector((state) => state.bgpermission);
  const customerinfo = useSelector((state) => state.crmcustomerinfo);
  const [isMuted, setIsMuted] = useState(false);
  const [callStatus, setCallStatus] = useState("oncall");
  const [isOnHold, setIsOnHold] = useState(false);
  const [callDuration, setCallDuration] = useState(0);
  const [callended, setCallended] = useState(true);
  const [callStartTime, setCallStartTime] = useState(null);
  const [ticektname, setTicketName] = useState("");
  const [phonenumber, setPhoneNumber] = useState("");
  const [showdispopage, setDispopage] = useState(false);
  const [transfercall, settransfercall] = useState(false);
  const [conferencecall, setconferencecall] = useState(false);
  const [calltransferStatus, setcalltransferStatus] = useState("");

  const config_api = JSON.parse(localStorage.getItem("config"));
  const local_customer_info = JSON.parse(localStorage.getItem('customer_info'))
  const agent_name = localStorage.getItem('username') 
  const userPermission = config_api.projects[0].userPermission;

  useEffect(() => {
    if(userPermission.auto_dispo == false){
    fetchDispo();
    }
  }, []);

  const startCallDuration = () => {
    if (!callStartTime) {
      setCallStartTime(new Date());
    }
  };
  const resetCallDuration = () => {
    setCallStartTime(null);
    setCallDuration(0);
  };
  const fetchDispo = async () => {
    dispatch(FetchDispo(userPermission.crm_call_purpose))
      .then((response) => {
        if (response.status === 200) {
          const extractedStatuses = response.data.data;
          let dispoobjdecode = Object.values(extractedStatuses);

          const dispoarraydecode = dispoobjdecode.map((dispo, index) => {
            // let dipostatus =dispo[0]
            // dispatch(setcallstatus(dipostatus))
            return dispo[0];

            // return {
            //     // Example transformation
            //     ...dispo,
            //     decodedIndex: index // Adding an example property
            // };
          });

          dispatch(setcallstatus(dispoarraydecode));
          // const dispodata = response.data.data
          //  const extractedDisopNames = dispoobjdecode.map(dispo => dispo.dispo_id);
          // setDispositionStatusOptions(extractedDisopNames);
        }
      })
      .catch((err) => {
        return err;
      });
  };

  useEffect(() => {
    const handleRefresh = (event) => {
      const message = "Agent On the call you want to leave?";

      event.returnValue = message; // Required for Chrome to show the alert
      if (
        localStorage.getItem("liveagentdata") == "RINGING" ||
        localStorage.getItem("liveagentdata") == "MANUAL" ||
        localStorage.getItem("liveagentdata") == "CONNECT"
      ) {
        // handleHangup()
        localStorage.setItem("livecall", false);
        dispatch(setshowcalllogpage(false));
      }
      return message; // Required for some other browsers
    };

    window.addEventListener("beforeunload", handleRefresh);

    return () => {
      window.removeEventListener("beforeunload", handleRefresh);
    };
  }, []);

  const crmviewlead = () => {
    let view_url;
    const viewlead_payload = {
      customer_id: customerinfo.customer_id,
      module: customerinfo.module,
    };
    dispatch(CrmUrl(viewlead_payload))
      .then((crmurlresponse) => {
        if (crmurlresponse.status == 200) {
          view_url = crmurlresponse.data.url.view_url;

          window.open(`${view_url}`);
        }
      })
      .catch((err) => {
        return err;
      });
  };

  const crmaddlead = () => {
    let add_url;

    dispatch(CrmUrl())
      .then((crmurlresponse) => {
        if (crmurlresponse.status == 200) {
          add_url = crmurlresponse.data.url.add_url;

          window.open(`${add_url}`);
        }
      })
      .catch((err) => {
        return err;
      });
  };

  useEffect(() => {
    startCallDuration();
    setCallended(true);
  }, [backgroundstate.rtcprocess]);

  useEffect(() => {
    if (callStartTime) {
      const timer = setInterval(() => {
        const currentTime = new Date();
        const durationInSeconds = Math.floor(
          (currentTime - callStartTime) / 1000
        );
        setCallDuration(durationInSeconds);
      }, 1000);

      return () => {
        clearInterval(timer);
      };
    }
  }, [callStartTime]);

  const toggleMute = () => {
    setIsMuted(!isMuted);

    if (session) {
      if (isMuted) {
        session.unmute();
        setCallStatus("oncall");
      } else {
        session.mute();
        setCallStatus("mute");
      }
    }
  };

  const toggleHold = () => {
    setIsOnHold(!isOnHold);

    if (session) {
      if (isOnHold) {
        session.unhold();
        setCallStatus("oncall");
      } else {
        session.hold();
        setCallStatus("hold");
      }
    }
  };

  const transfercallfun = () => {
    settransfercall(true);
    setconferencecall(false);
  };
  function conference() {
    setconferencecall(true);
    settransfercall(false);
  }

  function transferclose(value) {
    settransfercall(false);
    setconferencecall(false);
    if (value === "Transfer") {
      setcalltransferStatus(value);
    } else if (value === "Conference") {
      setcalltransferStatus(value);
    }
  }

  const handleSubmit = () => {

    try {


        const payload = {
            "station": config_api.projects[0].userInfo[0].user_phone_number,
            "phone_number": local_customer_info.customer_number,
            //"tenant_id": tenantid,
            "entity_type": local_customer_info.module,
            "user_id": config_api.projects[0].userInfo[0].user_id,
            "customer_id": local_customer_info.customer_id,
            "agent": { "identity": "user", "value": agent_name },
            "customer_name":local_customer_info.customer_name !== undefined?local_customer_info.customer_name :"Unkonwn Name",
            "call_type":local_customer_info.call_type,
            
        }




        dispatch(Hangup(payload))
            .then((disporesponse) => {
                if (disporesponse.status === 200) {
                    let customer_info = {
                        response_status:"",
                        customer_name:"",
                        customer_number: "",
                        module: "",
                        customer_id: "",
                        customer_leadstatus:"",
                        customer_leadowner:"",
                        call_type:""
                    }
                    dispatch(setcutomerinfo(customer_info))

                    localStorage.removeItem('customer_info')
                    localStorage.setItem('livecall', false)
                    localStorage.removeItem('callerinfo')
                    localStorage.removeItem('livecall')
                    if (customer_info.type == "C2C") {
                        dispatch(setshowcalllogpage(true))    
                    }else{
                        dispatch(setshowcalllogpage(false))
                    }
                    dispatch(setshowcalllogpage(true))
                    dispatch(rtcended(false))
                    dispatch(rtcprocess(true))
                    //action("dispo")

                }


            })
            .catch((err) => {
                
                return err

            });

      

    } catch (error) {
        console.log("error",error);
        
        return error
    }


}
  
  const handleHangup = () => {
   
    if (config_api.projects[0].userPermission.auto_dispo === true) {
    
      handleSubmit()
    } else {
      session.terminate();
    }

    setCallStatus("ended");
  };

  return (
    <div>
      <Comtemplete>
        <div className="webphone-top-container">
          <div className="call-duration-status">
            {formatCallDuration(callDuration)}

            <div>
              {callStatus === "oncall" && <span>On Call</span>}
              {callStatus === "ended" && <span>Call Ended</span>}
              {callStatus === "failed" && <span></span>}
              {callStatus === "hold" && <span>On hold</span>}
              {callStatus === "mute" && <span>On Mute</span>}
            </div>
          </div>

          <div className="call-animation">
            <img
              src={person}
              className="img-circle"
              alt="test"
              style={{ cursor: "pointer" }}
            />
          </div>

          <div className={`caller-info ${customerinfo.call_type}`}>
            <div> {customerinfo.customer_name} </div>
            <div>{customerinfo.customer_number}</div>
            <div>{customerinfo.queue_name}</div>
          </div>
        </div>

        <div className="webphone-bottom-container">
          <div className="webphone-bottom-container-row1">
            <button
              style={{ color: "#2196F3", fontSize: "20px" }}
              className="webphone-icons"
            >
              <FontAwesomeIcon
                title="Mute"
                icon={isMuted ? faMicrophoneSlash : faMicrophone}
              onClick={toggleMute}

              />
              {/* <div className="icon-label mute">Mute</div> */}
            </button>

            <button className="webphone-icons">
              <FontAwesomeIcon
                title="Hold"
                icon={isOnHold ? faPlay : faPause}
                style={{ color: "#2196F3", fontSize: "20px" }}
                onClick={toggleHold}
              />
              {/* <div className="icon-label hold">Hold</div> */}
            </button>
            {/* {!config_api.projects[0].userPermission.lead_view && */}
            <button
             
              className={
                config_api.projects[0].userPermission.lead_view
                  ? "webphone-icons"
                  : "webphone-icons disablebtn"
              }
            >
              <FontAwesomeIcon
                title="View Info"
                icon={faEye}
                onClick={crmviewlead}
                style={{ color: "#2196F3", fontSize: "20px" }}
              />
              {/* <div className="icon-label hold">Conference</div> */}
            </button>
            {/* } */}
          </div>

          <div className="webphone-bottom-container-row2">
            {/* {!config_api.projects[0].userPermission.view_transfer && */}
            <button
             
              className={
                config_api.projects[0].userPermission.view_transfer
                  ? "webphone-icons"
                  : "webphone-icons disablebtn"
              }
            >
              <FontAwesomeIcon
                title="Transfer"
                onClick={transfercallfun}
                icon={faShareSquare}
                style={{ color: "#2196F3", fontSize: "20px" }}
              />
              {/* <div className="icon-label hold">Transfer</div> */}
            </button>
            {/* } */}
            {/* {!config_api.projects[0].userPermission.lead_create && */}
            <button
            
              className={
                config_api.projects[0].userPermission.lead_create
                  ? "webphone-icons"
                  : "webphone-icons disablebtn"
              }
            >
              <FontAwesomeIcon
                title="Add Lead"
                onClick={crmaddlead}
                icon={faPlus}
                style={{ color: "#2196F3", fontSize: "20px" }}
              />
              {/* <div className="icon-label hold">Hangup</div> */}
            </button>
            {/* } */}
            {/* {!config_api.projects[0].userPermission.conference_view && */}

            <button
             
              className={
                config_api.projects[0].userPermission.conference_view
                  ? "webphone-icons"
                  : "webphone-icons disablebtn"
              }
            >
              <FontAwesomeIcon
                title="Conference"
                onClick={conference}
                icon={faUsers}
                style={{ color: "#2196F3", fontSize: "20px" }}
              />
              {/* <div className="icon-label hold">View</div> */}
            </button>
            {/* } */}
          </div>

          <div className="webphone-bottom-container-row3">
            <button  className="webphone-icons">
              <FontAwesomeIcon
                title="Hangup"
                onClick={handleHangup}
                icon={faPhone}
                style={{ color: "red", fontSize: "25px" }}
              />
              {/* <div className="icon-label hold">Hangup</div> */}
            </button>
          </div>

          {(transfercall || conferencecall) && (
            <>
              <TransferConference
                callernumber={customerinfo.customer_number}
                transferclose={transferclose}
                type={
                  transfercall === true
                    ? "Transfer"
                    : conferencecall === true
                    ? "Conference"
                    : ""
                }
              />
              {/* <button onClick={transferclose}>
                                            transfer page

                                        </button> */}
            </>
          )}
        </div>
      </Comtemplete>
    </div>
  );
}
export default Webphone;
