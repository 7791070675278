import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Queuelogo from '../assets/images/favicon.png'
import { LoginUserinfo } from '../redux/apicalling/dispatchfunction';
import '../assets/css/queuepage.css'
import Comtemplete from '../pages/comtemplete'
import Select from 'react-select'
import { toast } from 'react-toastify';

function Queuepage() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  let payload = {};
  const [queuevalue, setQueuevalue] = useState("");
  const [queuename, setQueuenames] = useState([]);
  
  const que_agentname = localStorage.getItem("username")
  
  const config_data = JSON.parse(localStorage.getItem("config"))
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 2;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  let queue_value = config_data.projects[0].queue
  const tenant_code = config_data.projects[0].tenant_code
  let config_user = config_data.projects[0].userInfo[0].user_email
  const station = config_data.projects[0].userInfo[0].user_phone_number
  
    payload['agent_id'] = tenant_code
    payload['avatar_url'] = ""
    payload['campaign'] = "doocti"
    payload['station'] = station
    payload['user'] = config_user
    payload['queue'] =  queuevalue
    
  
  
  useEffect(() => {
    const extractedQueueNames = queue_value.map(queue => ({
      value: queue.name,   // 'value' typically represents a unique identifier
      label: queue.name  // 'label' is what gets displayed in the dropdown
    }));
    setQueuenames(extractedQueueNames);
   
  }, []);

const handlequeuebackbutton = ()=>{
  localStorage.removeItem('loginpage1')
  localStorage.removeItem('apinfo')
  localStorage.removeItem('token')
  localStorage.removeItem('config')
  localStorage.removeItem('username')
  navigate('/')
}

  const handleQueuevalue = (event) => {
    
    // if (queuevalue.length === 0) {

    //     //toast.error('Please select a queue');
    //     setQueuevalue('');
    //     return;
    //   }
    // const { target: { value }, } = event;
    // const selectedValues = Array.isArray(value) ? value : [value];
    
    // setQueuevalue(selectedValues);
   
    dispatch(LoginUserinfo(payload)).then((response) => {
        if (response.status === 200) {
          navigate('/homepage')
          localStorage.setItem('queuelogin',true)
    
       }
        
      }).catch((err)=>{
         return err

      })
   
  };
 

const handlequeuevaluedropdown = (event) => {
  let selectedStatus = [];
    for (let i = 0; i < event.length; i++) {
      const element = event[i].value;
      selectedStatus.push(element)


    }

    setQueuevalue(selectedStatus.toString());

  
  setQueuevalue(selectedStatus.toString());

  };



  return (

<div>
        <Comtemplete>
          
         <div className='bgcover'>   
        
          <div className='queue-placeholdertext'> {"Select Queues "}</div>
          <div >
          <input className='queue-textbox' type='text' value = {station} readOnly={true}  ></input>
        </div>  

          <div className='queue-selectbox'>

             
         <Select
            isMulti={true}
            isDisabled={false}
            styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
            menuPortalTarget={document.body}
            menuShouldScrollIntoView={true}
            menuPlacement={`auto`}
            onChange={handlequeuevaluedropdown}
          
            options={queuename}
            isSearchable={true}
            className="basic-multi-select"
            classNamePrefix="select"
            placeholder={'Select Queues'}
          />




          </div>
         
             <button type="button" className="queue-button" onClick={handleQueuevalue} disabled={queuevalue.length === 0} >
            Proceed
          </button>
          <span className="back-to-login" onClick={handlequeuebackbutton} style={{ cursor: 'pointer' }}>
            Back to Login?
          </span>
  
          

          </div>
        </Comtemplete>
      </div>


  

  )
}
export default Queuepage;
