import React, { useState } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
// import Login from './components/login'; 
import Login from './pages/loginpage';
import Queuepage from './pages/queuepage';
import Homepage from './pages/homepage';
import CallLog from './pages/calllogpage';
import Dialpad from './pages/dialpad';
function App() {
  const [loggedInUser, setLoggedInUser] = useState(null);
    
    const handleLogin = (username) => {
    setLoggedInUser(username);
  };

  return (
    <Router>
      <Routes>
        <Route path="/" element={<Login onLogin={handleLogin} />} />
        <Route path="/queuepage" element={<Queuepage/>} />
        <Route path="/homepage" element={<Homepage/>}/>
        <Route path="/dialpadpage" element={<Dialpad/>}/>
        {/* <Route path="/homepage" element={<Homepage />} />
        
        <Route path="/template" element={<Comtemplete/>} /> */}
        </Routes>
    </Router>
  );
}

export default App;







// import React, { useState } from 'react';
// import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
// import Comtemplete from './pages/comtemplete';
// import Login from './pages/loginpage';
// function App() {
//   const [loggedInUser, setLoggedInUser] = useState(null);
    
//   const handleLogin = (username) => {
//   setLoggedInUser(username);
// };
  
//   return (
//     <Router>
//       <Routes>
//       <Route path="/" element={<Login onLogin={handleLogin} />} />  
//         {/* <Route path="/" element={<Login />} /> */}
//         </Routes>
//     </Router>
//   );
// }

// export default App;
