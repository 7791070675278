// rootReducer.js

import { combineReducers } from 'redux';
import sessionReducer from './RTCsession/sessionReducer';
import reducer from './backgroundpermission/permission-reducer';
import callerinsightReducer from './callerinsights/insightreducer';
import callstatusReducer from './callstatus/callstatusreducer';
import customerinfoReducer from './leadinfo/customerinforeducer';
import autoleadReducer from './autodial/autodialreducer';
// import loginreducer from './Login/LoginReducer.js';

const rootReducer = combineReducers({
//   auth: loginreducer,
     session:sessionReducer,
     bgpermission:reducer,
     callerinsight: callerinsightReducer,
     callstatus:callstatusReducer,
     crmcustomerinfo:customerinfoReducer,
     autoleads:autoleadReducer
    });

export default rootReducer;
