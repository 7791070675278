const initialState = {
  autodialeads: [],
  AutoDialSubfilter1: [],
  AutoDialSubfilter2: [],
  AutoDialPagecount: 0,
  AutoDialSkipcount: 0,
  AutoDialTakecount: 50,
  autodialplayparsebtn: false,
  autodialstatus: false,
  autodialcurrent: {},
  CustomViewvalue: [],
};

const autoleadReducer = (state = initialState, action) => {
  switch (action.type) {
    case "AUTO_DIAL_ACTION":
      if (action.payload.action == "push") {
        state.autodialeads = action.payload.payload;
      } else if (action.payload.action == "pop") {
        
        if (state.autodialeads.length > 0) {
          state.autodialeads.shift();
          state.autodialeads = state.autodialeads;
        }
      } else if (action.payload.action == "Allpop") {
        state.autodialeads = [];
      }
      return state;
    case "AUTO_DIAL_BUTTON_STATUS":
      state.autodialplayparsebtn = action.payload;
      return state;
    case "AUTO_DIAL_STATUS":
      state.autodialstatus = action.payload;
      return state;
    case "AUTO_DIAL_CURRENT":
      state.autodialcurrent = action.payload;
      return state;
    case "AUTO_DIAL_SUBFILTER1":
      state.AutoDialSubfilter1 = action.payload;
      return state;

    case "AUTO_DIAL_SUBFILTER2":
      state.AutoDialSubfilter2 = action.payload;
      return state;

    case "AUTO_DIAL_PAGECOUNT":
      state.AutoDialPagecount = action.payload;
      return state;

    case "AUTO_DIAL_SKIPCOUNT":
      state.AutoDialSkipcount = action.payload;
      return state;

    case "AUTO_DIAL_TAKECOUNT":
      state.AutoDialTakecount = action.payload;
      return state;

    case "CUSTOM_VIEW_VALUE":
      state.CustomViewvalue = action.payload;
      return state;

    default:
      return state;
  }
};

export default autoleadReducer;
