// index.js

import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';

import { createStore, applyMiddleware } from 'redux';
import {thunk} from 'redux-thunk';

import App from './App';
import rootReducer from './redux/rootreducer';

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const store = createStore(rootReducer, applyMiddleware(thunk));

ReactDOM.render(
  <Provider store={store}>
    <App />
    <ToastContainer autoClose={3000} /> {/* Set your desired options */}
    {/* <Authtoken/> */}
  </Provider>,
  document.getElementById('root')
);




