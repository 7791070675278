import * as actions from './permission-type';

export const rtcprocess = data =>{
    return{
        type:actions.RTCPROCESS,
        payload:data
      }
} 

export const rtcended = data =>{
    return{
        type:actions.RTCENDED,
        payload:data
      }
} 

export const rtcfailed = data =>{
    return{
        type:actions.RTCFAILED,
        payload:data
      }
} 

export const oncall = data => {
    return {
        type: actions.ONCALL,
        payload: data
    }
}


export const setwebrtcstatus = (data) => ({
    type: 'SET_WEBRTCSTATUS',
    payload: data,
  });

  export const setshowcalllogpage = (data) => ({
    type: 'SET_SHOWCALLLOGPAGE',
    payload: data,
  });  

  export const setagentstatus = (data) => ({
    type: 'SET_AGENTSTATUS',
    payload: data,
  }); 

  export const setagentreadystatus = (data) => ({
    type: 'AGENT_READY_STATUS',
    payload: data,
  });

  export const setliveagentdata = (data) => ({
    type: 'SET_LIVEAGENT_DATA',
    payload: data,
  })