// import {
//     ONCALL,
//     RTCPROCESS,RTCFAILED,RTCENDED
//      } from "./BackgroundTypes";
import { ONCALL, RTCPROCESS, RTCENDED, RTCFAILED, SET_WEBRTCSTATUS, SET_SHOWCALLLOGPAGE, SET_AGENTSTATUS, AGENT_READY_STATUS,SET_LIVEAGENT_DATA } from "./permission-type";
const initialState = {
  oncall: false,
  rtcprocess: false,
  rtcfailed: false,
  rtcended: false,
  setwebrtcstatus: true,
  setshowcalllogpage: true

};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case ONCALL:
      return {
        ...state,
        oncall: action.payload,
      };

    case RTCPROCESS:
      return {
        ...state,
        rtcprocess: action.payload,
      };

    case RTCFAILED:
      return {
        ...state,
        rtcfailed: action.payload,
      };

    case RTCENDED:

      return {
        ...state,
        rtcended: action.payload,
      };

    case SET_WEBRTCSTATUS:

      return {
        ...state,
        setwebrtcstatus: action.payload,
      };
    case SET_AGENTSTATUS:
      return {
        ...state,
        setagentstatus: action.payload,
      };

    case SET_SHOWCALLLOGPAGE:
      return {
        ...state,
        setshowcalllogpage: action.payload,
      };

    case AGENT_READY_STATUS:
      return {
        ...state,
        liveReadyAgentstatus: action.payload,
      };

    case SET_LIVEAGENT_DATA:
      return {
        ...state,
        liveReadyAgentstatus: action.payload,
      };

    case 'SET_LIVEAGENT_DATA':
      return {
        ...state,
        setliveagentdata: action.payload,
      };









    default:
      return state;
  }
};
export default reducer;
