import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone } from '@fortawesome/free-solid-svg-icons';
import '../assets/css/incomingcallpage.css'
function generateLetterImage(letter) {
    // Create a canvas element
    const canvas = document.createElement('canvas');
    canvas.width = 100; // Set width
    canvas.height = 100; // Set height

    // Get 2D context
    const ctx = canvas.getContext('2d');
    ctx.fillStyle = '#53a9e5'; // Set background color
    ctx.fillRect(0, 0, canvas.width, canvas.height);

    ctx.fillStyle = '#ffffff'; // Set text color
    ctx.font = '48px Arial'; // Set font size and family
    ctx.textAlign = 'center';
    ctx.textBaseline = 'middle';
    ctx.fillText(letter.toUpperCase(), canvas.width / 2, canvas.height / 2); // Draw letter

    return canvas.toDataURL(); // Get image as data URL
}

const Incomingcallscreen = ({ onAnswer, onDecline, leadname, leadnumber }) => {
    const [caller_name, setCallerName] = useState(leadname)
    const [caller_number, setCallerNumber] = useState(leadnumber)
    const caller_insight = useSelector((state) => state.callerinsight);
    const customer_info = useSelector((state) => state.crmcustomerinfo)
    // const local_customer_info = JSON.parse(localStorage.getItem('customer_info'))
   
    useEffect(() => {
        setCallerName("Hari")
        setCallerNumber(leadnumber)

        const handleBeforeUnload = (event) => {
           
            localStorage.removeItem('livecall')
            const message = "Are you sure you want to leave this page? Your call will be disconnected.";
            event.preventDefault(); // Required for Chrome
            event.returnValue = message; // Required for other browsers
            return message;
        };

        window.addEventListener('beforeunload', handleBeforeUnload);

        // Cleanup event listener on unmount
        return () => {
            
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };

    }, [])



    const firstLetter = leadname.charAt(0).toUpperCase();
    const letterImage = generateLetterImage(firstLetter);


    const maskPhoneNumber = (phoneNumber) => {
        const maskedNumber = phoneNumber.slice(0, -4).padEnd(phoneNumber.length, 'X');
        return maskedNumber;
    };

    return (
        <div className="incoming-call-container">

            <div className="incoming-call-top-container">
                <div className='calltye-header'>Incoming call</div>
                {/* <img src={letterImage} alt={firstLetter} className="letter-image" /> */}
                <div className="incomingcaller-info">
                    <div>{customer_info.customer_name}</div>
                    <div>{customer_info.customer_number}</div>
                    <div>{customer_info.queue_name !== '' ? customer_info.queue_name :''}</div>
                </div>

                <div className="call-actions">
                    <div className="answer-button">
                        <button onClick={onAnswer} className="animate-answer" >
                            <FontAwesomeIcon icon={faPhone} />
                        </button>
                    </div>

                    <div className="decline-button">
                        <button onClick={onDecline}>
                            <FontAwesomeIcon icon={faPhone} />
                        </button>
                    </div>

                </div>

            </div>

            <div className='incoming-call-bottom-container'>

                <div className='call-info-title'> Caller Insight </div>


                <div className='ib-call-info'>
                   {customer_info.response_status == 200 ?
                        (
                            <div>
                                
                                <div className='ib-call-info-data-container'>
                                    <div>{customer_info.module+""+"Id"}</div>
                                    <div className='call-insigt'>{customer_info.customer_id}</div>
                                </div>
                                

                                {/* <div className='ib-call-info-data-container'>
                                    <div>{customer_info.module+""+"owner"}</div>
                                    <div className='call-insigt'>{customer_info.customer_leadowner}</div>
                                </div> */}

                                <div className='ib-call-info-data-container'>
                                    <div>{customer_info.module+""+"Status"}</div>
                                    <div className='call-insigt'>{customer_info.customer_leadstatus}</div>
                                </div>

                                <div className='ib-call-info-data-container'>
                                    <div>Entity Type</div>
                                    <div className='call-insigt'>{customer_info.module}</div>
                                </div>



                            </div>
                        ) : (
                            <div className='insight-nodata'>Data Not Found</div>
                        )

                    }



                </div>



            </div>

        </div>
    );
};

export default Incomingcallscreen;

