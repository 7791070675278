import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import logo from "../assets/images/Dooctilogo.svg";
import person from "../assets/images/person.svg";
import Sidebar from './Sidebar.js'; // Import the Sidebar component
import "../assets/css/comtemplete.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPowerOff } from '@fortawesome/free-solid-svg-icons';
import { faBars } from '@fortawesome/free-solid-svg-icons'; // Icon for sidebar toggle

function Comtemplete({ children, toggleUserOptions, badge, logout, handleSetBadge, awayOptionsOpen }) {
    const [isSidebarOpen, setIsSidebarOpen] = useState(false); // State for sidebar
    const backgroundstate = useSelector((state) => state.bgpermission);
    const queuestatus = JSON.parse(localStorage.getItem('queuelogin'));
    const liveagentdata = localStorage.getItem('liveagentdata')
  

    // const toggleSidebar = () => {
    //     setIsSidebarOpen(!isSidebarOpen);
    // };

    return (
        <div>
            <div className='topheader'>
                <header className="header-top dropshadow">
                    <div className="header-content">
                        <img className="logo-image" src={logo} alt="Logo" />
                        {/* <button className="sidebar-toggle" onClick={toggleSidebar}>
                            <FontAwesomeIcon icon={faBars} />
                        </button> */}
                        {(liveagentdata == "READY"  || liveagentdata == "PAUSE") && (
                            <div>
                                <div className="button-logout">
                                    <div className="betweenspace">
                                        <div className="user-options" id="userOptions">
                                            {/* Render Online option if awayOptionsOpen is true */}
                                            {(awayOptionsOpen) ? (
                                                <div className="option" id="onlineOption" onClick={() => handleSetBadge('Online')}>
                                                    Online
                                                </div>
                                            ) : (
                                                <div className="badge-grey">
                                                    <div className="away-options">
                                                        {/* Render away options dynamically */}
                                                        {['Lunch', 'Meeting', 'Break'].map((option) => (
                                                            <div className="option" key={option} onClick={() => handleSetBadge(option)}>
                                                                {option}
                                                            </div>
                                                        ))}
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                        <img className="userlogo" src={person} title="Agent Status" onClick={toggleUserOptions} />
                                        {/* {badge === 'Online' && <div className="badge-green"></div>} */}
                                        {/* {badge === 'Offline' && <div className="badge-red"></div>} */}
                                        {/* {awayOptionsOpen && <div className="badge-grey"></div>} */}

                                        {liveagentdata == "READY" ? (

                                            <div className="badge-green"></div>
                                        ) : (
                                            <div className="badge-grey"></div>
                                        )}

                                    </div>
                                    <button className="userlogout" onClick={logout} style={{ color: 'white', border: 'none', fontSize: '18px',cursor:"pointer" }}>
                                        <FontAwesomeIcon title="Logout" icon={faPowerOff} />
                                    </button>
                                </div>
                            </div>
                        )}
                    </div>
                </header>
            </div>
            {/* <Sidebar isOpen={isSidebarOpen} toggleSidebar={toggleSidebar} /> */}
            <div className='contentspace'>
                {children}
            </div>
        </div>
    );
}

export default Comtemplete;
