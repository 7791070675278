import axios from "axios";
var apinfo = JSON.parse(localStorage.getItem("apinfo")) || "{}"
let axiosInstance = axios.create({

       baseURL: apinfo.dialer_url,
    //   api_url
    //    baseURL: "http://localhost:3001",
    timeout: 180000,

});

if(apinfo.token !== undefined){
    axiosInstance.defaults.headers.common['Authorization'] =`Bearer ${apinfo.token}`;

}

axiosInstance.interceptors.request.use(
    (config) => {
        return config;
    },
    (error) => Promise.reject(error)
);

axios.interceptors.response.use(
    (config) => {
        return config;
    },
    (e) => {
        return Promise.reject(e);
    }
);
export default axiosInstance;
/**
 * @return The adapter that axios uses for dispatching requests. This may be overwritten in tests.
 *
 * @see https://github.com/axios/axios/tree/master/lib/adapters
 * @see https://github.com/ctimmerm/axios-mock-adapter/blob/v1.12.0/src/index.js#L39
 */
export const getDefaultAdapter = () => axios.defaults.adapter;