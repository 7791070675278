import React, { useState, useEffect } from 'react'
import { Form, Button } from 'react-bootstrap'
import '../assets/css/transfercall.css'
import nodataimg from '../assets/images/nodataclippad.svg'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faArrowLeft, faPhone } from '@fortawesome/free-solid-svg-icons'; // Import the icon for the clear button
import { AsterOtherAgent, Transfercallapi, Conferencecallapi } from '../redux/apicalling/dispatchfunction';
import { useDispatch } from 'react-redux';
import person from "../assets/images/person.svg"
const TransferConference = (props) => {
  const dispatch = useDispatch();
  const config_data = JSON.parse(localStorage.getItem("config"))
  const [xfertype, setxfertype] = useState(props.type === "Transfer" ? "agent" : "others");
  const [readyAgents, setreadyAgents] = useState([]);
  const [queuename, setQueuenames] = useState([]);
  const [dial, setdial] = useState('')
  const [Options, setOptions] = useState(props.type === "Transfer" ? [{ lable: "Agent", value: 'agent' }, { lable: "Queue", value: 'queue' }, { lable: "Others", value: 'others' }] : [{ lable: "Others", value: 'others' }])
  let config_user = config_data.projects[0].userInfo[0]
  useEffect(() => {
    var interval = null;
    if (xfertype === "agent") {
      dispatch(AsterOtherAgent({
        "user": config_user.user_email,
        "name": ""
      }))
        .then(async function (response) {

          if (response.status === 200) {
            let arr = []
            for (let i = 0; i < response.data.data.length; i++) {
              const element = response.data.data[i];

              if (element.status === "READY") {
                arr.push(element)
              }

            }
            setreadyAgents(arr)
          }
        }).catch((err) => {
          return err

        })
      interval = setInterval(() => {
        dispatch(AsterOtherAgent({
          "user": config_user.user_email,
          "name": ""
        }))
          .then(async function (response) {

            if (response.status === 200) {
              let arr = []
              for (let i = 0; i < response.data.data.length; i++) {
                const element = response.data.data[i];

                if (element.status === "READY") {
                  arr.push(element)
                }

              }
              setreadyAgents(arr)
            }
          }).catch((err) => {
            return err

          })

      }, 2000)
    } else {
      setreadyAgents([])
      clearInterval(interval);

    }
    return () => clearInterval(interval);
  }, [xfertype])
  const handleChange = (event) => {
    if (event === "queue") {
      const config_data = JSON.parse(localStorage.getItem("config"))

      let queue_value = config_data.projects[0].queue
      const extractedQueueNames = queue_value.map(queue => queue.name);
      setQueuenames(extractedQueueNames);
    }
    setxfertype(event);


  };
  const transferdata = (type, data) => {
    if (props.type === 'Transfer') {
      let payload = {
        agent: config_user.user_email,
        station: config_user.user_phone_number,
        phone_number: props.callernumber,
        xfer_type: xfertype,
        xfer_to: type === "agent" ? data.station : data
        // uniqueid: Joi.string().required()
      }
      dispatch(Transfercallapi(payload))
        .then(async function (response) {
          if (response.data.status === 200) {
            props.transferclose(props.type)
          }
        })
    } else {
      let payload = {
        agent: config_user.user_email,
        station: config_user.user_phone_number,
        phone_number: props.callernumber,
        party_number: type === "agent" ? data.station : data
      }
      dispatch(Conferencecallapi(payload))
        .then(async function (response) {
          if (response.data.status === 200) {
            props.transferclose(props.type)
          }
        })
    }

  }
  return (

    <div className="transfercall">

      <div className="transferheader">

        <div className="header-icons">

          <div className="options-nav">
            {props.type === "Transfer" ? (
              <>
                {['agent', 'queue'].map(option => (
                  <button
                    key={option}
                    className={`nav-button ${xfertype === option ? 'active' : ''}`}
                    onClick={() => handleChange(option)} // Assuming you have a function to set xfertype
                  >
                    {option.charAt(0).toUpperCase() + option.slice(1)}
                  </button>
                ))}
              </>
            ) : null}

            <button
              className={`nav-button ${xfertype === "others" ? 'active' : ''}`}
              onClick={() => handleChange("others")}
            >
              Others
            </button>
          </div>



          {/* Dynamic navigation buttons for options */}
          {/* <div className="options-nav">
 
          {props.type !== "Conference" && (
          <>
            {['agent', 'queue'].map(option => (
              <button
                key={option}
                className={`nav-button ${xfertype === option ? 'active' : ''}`}
                onClick={() => handleChange(option)} // Assuming you have a function to set xfertype
              >
                {option.charAt(0).toUpperCase() + option.slice(1)}
              </button>
            ))}
          </>
        )}
                 


            
          </div> */}




          {/* Close Icon */}


          <div className="back-icon-transfer" >
            <FontAwesomeIcon icon={faArrowLeft} style={{ color: '#007bff', fontSize: '20px', }}
              onClick={() => {

                props.transferclose(undefined);
              }}
            />
          </div>
        </div>
      </div>

      <div>
        {xfertype === "agent" && (
          <div className='container_transfer'>
            {readyAgents.map((agent, index) => (
              <div key={index} onClick={() => transferdata(xfertype, agent)} className="contact-details_data">
                <div className='transferperson-img-outine'>
                  <img className='transferperson-img-inline' src={person}></img>
                </div>
                <div className='transferdata'>
                  <div>{agent.user}</div>
                  {/* <p className='pnumber'>{agent.queue}</p> */}
                  <p className="call-type">{agent.station}</p>


                </div>

              </div>


            ))}
            {readyAgents.length === 0 && <div className='trans_nodata'>
              <div>
                <div ><img className='transfer-nodata-img' src={nodataimg}></img> </div>
                <div className='transfer-no-data-found'>No Data Found</div>
              </div>
            </div>}
          </div>
        )}

        {xfertype === "queue" && (
          <div className='container_transfer'>
            {queuename.map((queue, index) => (
              <div key={index} className="contact-details_data">

                <div className='transfer-queue-data'>
                  <div>{queue}
                    <div className='transfer-queue-phone-icon'>
                      <FontAwesomeIcon
                        icon={faPhone}
                        style={{ color: 'green', fontSize: '20px', }}
                        onClick={() => transferdata(xfertype, queue)}
                      />
                    </div>
                  </div>


                </div>

              </div>
            ))}
            {queuename.length === 0 && <div>

              <div >
                <img className='transfer-queue-nodata-img' src={nodataimg}></img>
              </div>
              <div className='transfer-queue-nodata-found'>No Data Found</div>


            </div>}
          </div>
        )}

        {xfertype === "others" && (
          <div className='container_transfer'>


            <input className='pad_' type="text" placeholder="Please Enter Number" value={dial} onChange={(e) => setdial(e.target.value)} />

            {dial !== "" && (
              <button className='transfer-button' onClick={() => transferdata(xfertype, dial)}>
                {props.type === "Transfer" ? "Transfer" : 'Connect'}
              </button>
            )}
          </div>
        )}
      </div>
    </div>









  );


};
export default TransferConference;
