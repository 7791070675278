const initialState = {
  response_status: "",
  customer_name: "",
  customer_number: "",
  module: "",
  customer_id: "",
  customer_leadstatus: "",
  customer_leadowner: "",
  call_type: ""
}; 

const customerinfoReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_CUSTOMERINFO':
        return action.payload; // Set the session value
    
    default:
      return state;
  }
};

export default customerinfoReducer;
