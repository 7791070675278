import React, { useState } from 'react'
import { Form, Button } from 'react-bootstrap'
import '../assets/css/dialpad.css'
import { useDispatch } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone, faTimes,faArrowLeft,faBackspace } from '@fortawesome/free-solid-svg-icons'; // Import the icon for the clear button
import Comtemplete from './comtemplete';
import { setshowcalllogpage } from '../redux/backgroundpermission/permission-action';


const Dialpad = () => {
  
   const dispatch = useDispatch();
  const [dial, setDial] = useState('')
  const [closepopop, setClosepopop] = useState(true);
  const setDialFun = (number) => {
    
    const num = dial + '' + number
    setDial(num)

  }

  const dialcall = () => {
     let data ={
       
         number:dial
     }
    window.postMessage({  type:'Manual', data: data });

  }

  const slicenumber = () => {
    setDial(dial.slice(0, -1));
  }
  
  const handleInputChange = (e) => {
    const value = e.target.value;
    // Allow only digits and * or #
    if (/^[0-9+]*$/.test(value)) {
        setDial(value);
    } else {
        // Optionally show an error or prevent invalid characters
        e.target.value = dial; // Reset to last valid value
    }
};

  return (
    
      <div className="dialpad">
        <div className='pad-shownumber'>
          <Form.Control
            type="text"
            value={dial}
            className="pad"
            onChange={handleInputChange}
          />
        </div>
        <div className="dialpad_sub_container">



          <div className="dialnumber">
            <div>
              <span onClick={() => setDialFun(1)}>1</span>
              <span onClick={() => setDialFun(2)}>2</span>
              <span onClick={() => setDialFun(3)}>3</span>
            </div>
            <div>
              <span onClick={() => setDialFun(4)}>4</span>
              <span onClick={() => setDialFun(5)}>5</span>
              <span onClick={() => setDialFun(6)}>6</span>
            </div>
            <div>
              <span onClick={() => setDialFun(7)}>7</span>
              <span onClick={() => setDialFun(8)}>8</span>
              <span onClick={() => setDialFun(9)}>9</span>
            </div>
            <div>
            
              <span onClick={() => setDialFun(0)}>0</span>
              {/* <span onClick={() => setDialFun("+")}>+</span> */}
             
            </div>
            <div>
              
                <button className="call-button" onClick={dialcall} disabled={dial.length < 5}>
                  <FontAwesomeIcon style={{ color: '#fff', fontSize: '15px', textAlign: 'center',cursor:'pointer' }} icon={faPhone} />
                </button>
            

              <div >
                
                <FontAwesomeIcon style={{ color: '#3498db', fontSize: '15px',cursor:"pointer" }} icon={faBackspace} onClick={slicenumber} />
              </div>


            </div>


          </div>
          <div>



          </div>
        </div>
      </div>
     
   
  )

};

export default Dialpad;










