import React, { useState, useEffect, useRef } from "react";
import { toast } from "react-toastify";
import _ from "lodash"; // Using lodash for debounce
import favicon from "../assets/images/favicon.png";
import { useDispatch, useSelector } from "react-redux";
import pausewave from "../assets/images/pause.jpg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Hangup,
  Resume,
  Pause,
  Logout,
  CreateTicket,
  FetchTicket,
  MakeManualcall,
  FetchDispo,
  CustomerInfo,
  AgentFetch,
  Createcrm,
} from "../redux/apicalling/dispatchfunction";
import JsSIP from "jssip";
import Autodial from "./autodialpage";
import { useNavigate } from "react-router-dom";
import ringtoneSrc from "../assets/audio/ringtone.wav";
import Dialpad from "./dialpad";
import {
  faPhone,
  faArrowLeft,
  faHouse,
  faUser,
  faDashboard,
  faKiss,
  faKeyboard,
} from "@fortawesome/free-solid-svg-icons";
import Comtemplete from "./comtemplete";
import CallLog from "./calllogpage";

import Webphone from "../components/webphone";
import BottomNavigator from "../components/bottomnavigation";
import Incomingcallscreen from "../components/incomingcallpage";
import { setSession } from "../redux/RTCsession/sessionAction";
import { setCallerInsights } from "../redux/callerinsights/insightaction";
import { setcallstatus } from "../redux/callstatus/callstatusaction";
import {
  rtcprocess,
  rtcfailed,
  rtcended,
  setshowcalllogpage,
  setagentstatus,
  setagentreadystatus,
} from "../redux/backgroundpermission/permission-action";
import Disposcreen from "./dispopage";
import { setcutomerinfo } from "../redux/leadinfo/customerinfoaction";
import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import { AutoDialCurrent, Autolead } from "../redux/autodial/autodialaction";
import Dashboard from "./dashboardpage";
import "../assets/css/bottomnavigation.css";
import { red } from "@mui/material/colors";
// import customerinfoReducer from '../redux/leadinfo/customerinforeducer';
function formatCallDuration(durationInSeconds) {
  const minutes = Math.floor(durationInSeconds / 60)
    .toString()
    .padStart(2, "0");
  const seconds = (durationInSeconds % 60).toString().padStart(2, "0");
  return `${minutes}:${seconds}`;
}
function Homepage() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user_name1 = localStorage.getItem("username");
  const [tenantid, setTenantId] = useState("");
  const [useremail, setUserEmail] = useState("");
  const [entity_type, setEntityType] = useState("");
  const [userid, setUserId] = useState("");
  const [entity_id, setEntityId] = useState("");
  const [isListenerAdded, setIsListenerAdded] = useState(false); // Track if listener is already added

  const [response, setResponse] = useState("");
  const [phone, setPhone] = useState("");
  const [callStatus, setCallStatus] = useState("On Call1"); // Initialize as 'idle'
  const [pause, setPause] = useState(false);
  const [awayOptionsOpen, setAwayOptionsOpen] = useState(false); // New state variable
  const [badge, setBadge] = useState("Away"); // Initialize with the default status
  const [isDispositionSelected, setIsDispositionSelected] = useState(false);
  const [webphone, setWebphone] = useState(false);

  const [callername, setcallername] = useState("");
  const [callernumber, setCallernumber] = useState("");

  const [leadname, setLeadName] = useState("Unknown Name"); //incomingcall
  const [station, setStation] = useState("");
  const [incomingscreen, setIncomingscreen] = useState(false);
  const [inboundcall, setInboundcall] = useState(false); // show + and eye icon in IB call
  const [showdashboard, setShowDashboard] = useState(false);
  const [sipOptions, setsipOptions] = useState({
    mediaConstraints: {
      audio: true,
      video: false,
    },
  });
  const [callDuration, setCallDuration] = useState(0);
  const [isMuted, setIsMuted] = useState(false);
  const [isOnHold, setIsOnHold] = useState(false);
  const [callStartTime, setCallStartTime] = useState(null);
  const [incomingdetails, setIncomingdetails] = useState(false);
  const [dispositionStatusOptions, setDispositionStatusOptions] = useState("");
  const [callNotes, setCallNotes] = useState("");
  const [dispositionStatus, setDispositionStatus] = useState("");
  const [incomingdata, setIncomingdata] = useState("");
  const [inboundnumber, setInboundnumber] = useState("");
  //Inbound lead detail
  const [ibleadowner, setIbLeadOwner] = useState("");
  const [ibleaddate, setIbLeadDate] = useState("");
  const [ibleadid, setIbLeadId] = useState("");
  const [starttimer, setStarttimer] = useState(false);
  const [callended, setCallended] = useState(true);
  const [crmownername, setcrmownername] = useState("");

  const ringtoneRef = useRef(new Audio(ringtoneSrc));
  let station2 = localStorage.getItem("station");
  const backgroundstate = useSelector((state) => state.bgpermission);
  const [showDialpad, setShowDialpad] = useState(false);

  const [iconType, setIconType] = useState(faPhone); // Manage the icon type
  const [showCallLog, setShowCallLog] = useState(
    backgroundstate.setshowcalllogpage
  ); // Initial state from backgroundstate
  const agentavailable_state = JSON.parse(localStorage.getItem("agentstatus"));
  const [Autodialflag, SetAutodialflag] = useState(false);
  const [is_incoming, setis_incoming] = useState(false);
  const [barmenuvalue, setbarmenuvalue] = useState("1");
  const [isOnline, setIsOnline] = useState(navigator.onLine);

  const [liveagentdata, setLiveagentdata] = useState("");
  let local_showautodialpage = JSON.parse(
    localStorage.getItem("Showautodialpageui")
  );
  let livecall_status = JSON.parse(localStorage.getItem("livecall"));
  const config_api = JSON.parse(localStorage.getItem("config"));
  const customer_info_state = useSelector((state) => state.crmcustomerinfo);
  const [isTabActive, setIsTabActive] = useState(true);
  let sip_status = JSON.parse(localStorage.getItem("sipstatus"));
  const local_customer_info = JSON.parse(localStorage.getItem("customer_info"));
  const agent_name = localStorage.getItem("username");
  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.visibilityState === "visible") {
        setIsTabActive(true); // Tab is active
        localStorage.setItem("isTabActive", true);
      } else {
        setIsTabActive(false); // Tab is inactive
        localStorage.setItem("isTabActive", false);
      }
      //   const isActive = !document.hidden;  //browser tab is changed
      //   localStorage.setItem("isTabActive", isActive); // Store active status in localStorage
    };

    document.addEventListener("visibilitychange", handleVisibilityChange);

    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, []);

  useEffect(() => {
    setShowCallLog(backgroundstate.setshowcalllogpage);
  }, [backgroundstate.setshowcalllogpage]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      fetchliveagent();
      setIsOnline(navigator.onLine);
      if (navigator.onLine == false) {
        toast.error("Network Disconnected");
      }
    }, 2000);

    return () => clearInterval(intervalId);
  }, []);

  // useEffect(() => {
  //     const handleRefresh = (event) => {
  //         const message = "Agent On the call you want to leave?";

  //         event.returnValue = message; // Required for Chrome to show the alert
  //         if (localStorage.getItem('liveagentdata') == "RINGING" || localStorage.getItem('liveagentdata') == "RINGING" == "CONNECT" ) {
  //             localStorage.setItem('livecall', false)
  //             response.terminate(sipOptions)
  //         }
  //         return message; // Required for some other browsers
  //     };

  //     window.addEventListener('beforeunload', handleRefresh);

  //     return () => {
  //         window.removeEventListener('beforeunload', handleRefresh);
  //     };
  // }, []);

  useEffect(() => {
    if (showCallLog) {
      setIconType(faPhone);
    }
  }, [showCallLog]);
  // let livecall_status = JSON.parse(localStorage.getItem('livecall'))

  // useEffect(() => {
  //     if (livecall_status) {
  //          dispatch(rtcended(true))
  //         dispatch(setshowcalllogpage(false))
  //     }
  // }, [livecall_status])

  const fetchliveagent = () => {
    const liveagentpayload = {
      user: user_name1,
    };
    dispatch(AgentFetch(liveagentpayload))
      .then((liveagentres) => {
        if (liveagentres.data.data.length > 0) {
          setLiveagentdata(liveagentres.data.data[0].status);
          localStorage.setItem(
            "liveagentdata",
            liveagentres.data.data[0].status
          );
          dispatch(setagentstatus(liveagentres.data.data[0]));
          if (liveagentres.data.data[0].status == "READY") {
            // localStorage.setItem('livecall', false)
            dispatch(setagentstatus(false));
            dispatch(setagentreadystatus(true));
            setAwayOptionsOpen(false);
          } else if (liveagentres.data.data[0].status == "DISPO") {
            localStorage.setItem("livecall", false);
            setAwayOptionsOpen(true);
          } else {
            setAwayOptionsOpen(true);
            dispatch(setagentreadystatus(false));
          }
        } else {
          dispatch(setagentreadystatus(false));
          forcelogout();
        }
      })
      .catch((err) => {
        return err;
      });
  };

  const action = async (type, value) => {
    if (type === "auto") {
      SetAutodialflag(value);
    } else if (type === "dispo") {
      
      if (Autodialflag && value == "auto" ) {
        
        await dispatch(Autolead("pop"));
        handlechangebarmenu(undefined, "1");
        handlechangebarmenu(undefined, "2");
        // handlechangebarmenu(undefined, "3")
        setShowDialpad(false);
      }
      setis_incoming(false);
    }
  };

  let webrtc_status = backgroundstate.setwebrtcstatus;

  useEffect(() => {
    if (webrtc_status && agentavailable_state && isTabActive) {
      webrtc();
    }
  }, [webrtc_status, isTabActive]);

  useEffect(() => {
    try {
      if (!isTabActive && !sip_status) {
        phone.unregister();
      }
    } catch (error) {
      return;
    }
  });

  const handleDial = (number) => {
    response.sendDTMF(number);
  };

  const unregisterWebRTC = () => {
    if (phone) {
      // phone.unregister(); // Unregister from the SIP server
      localStorage.setItem("resume", JSON.stringify(false));
    }
  };

  const toggleMute = () => {
    setIsMuted(!isMuted);

    if (response) {
      if (isMuted) {
        response.unmute();
        setCallStatus("oncall");
      } else {
        response.mute();
        setCallStatus("mute");
      }
    }
  };

  const forcelogout = () => {
    localStorage.removeItem("login");
    localStorage.removeItem("Showautodialpageui");
    localStorage.removeItem("livecall");
    localStorage.removeItem("loginpage1");
    localStorage.removeItem("hanguppayload");
    localStorage.removeItem("resume");
    localStorage.removeItem("agentstatus");
    localStorage.removeItem("config");
    localStorage.removeItem("username");
    localStorage.removeItem("apinfo");
    localStorage.removeItem("token");
    localStorage.removeItem("callstatus");
    localStorage.removeItem("queuelogin");
    //localStorage.removeItem('crmuserinfo');
    localStorage.removeItem("entititytype");
    localStorage.removeItem("station");
    localStorage.removeItem("livecall");
    localStorage.removeItem("callstatus");
    localStorage.removeItem("liveagentdata");
    localStorage.removeItem("setplaybtnflag");
    localStorage.removeItem("customer_info");
    localStorage.removeItem("isTabActive");
    localStorage.removeItem("sipstatus");
    localStorage.removeItem("unregisterstatus");
    localStorage.removeItem("windowTabId");

    navigate("/");
    window.location.reload();
  };

  const toggleHold = () => {
    setIsOnHold(!isOnHold);

    if (response) {
      if (isOnHold) {
        response.unhold();
        setCallStatus("oncall");
      } else {
        response.hold();
        setCallStatus("hold");
      }
    }
  };

  const crmaddlead = () => {};
  const crmviewlead = () => {};
  const handleCallNotesChange = (event) => {
    setCallNotes(event.target.value);
  };
  const handleDispositionStatusChange = (event) => {
    const selectedStatus = event.target.value;
    setDispositionStatus(selectedStatus);
    setIsDispositionSelected(!!selectedStatus);
  };
  const handleSubmit = () => {
    try {
      const local_customer_info1 = JSON.parse(localStorage.getItem("customer_info"));
      const payload = {
        station: config_api.projects[0].userInfo[0].user_phone_number,
        phone_number: local_customer_info1.customer_number,
        //"tenant_id": tenantid,
        entity_type: local_customer_info1.module,
        user_id: config_api.projects[0].userInfo[0].user_id,
        customer_id: local_customer_info1.customer_id,
        agent: { identity: "user", value: agent_name },
        customer_name:
        local_customer_info1.customer_name !== undefined
            ? local_customer_info1.customer_name
            : "Unkonwn Name",
        call_type: local_customer_info1.call_type,
        
      };

      dispatch(Hangup(payload))
        .then((disporesponse) => {
          if (disporesponse.status === 200) {
            let customer_info = {
              response_status: "",
              customer_name: "",
              customer_number: "",
              module: "",
              customer_id: "",
              customer_leadstatus: "",
              customer_leadowner: "",
              call_type: "",
              call_outcome: ""
            };
            dispatch(setcutomerinfo(customer_info));
            console.log('this is check 3')
            localStorage.removeItem("customer_info");
            localStorage.setItem("livecall", false);
            localStorage.removeItem("callerinfo");
            localStorage.removeItem("livecall");
            if (customer_info.type == "C2C") {
              dispatch(setshowcalllogpage(true));
            } else {
              dispatch(setshowcalllogpage(false));
            }
            dispatch(setshowcalllogpage(true));
            dispatch(rtcended(false));
            dispatch(rtcprocess(true));
            //action("dispo")
          }
        })
        .catch((err) => {
          return err;
        });
    } catch (error) {
      console.log("error", error);

      return error;
    }
  };

  const getconfigdata = localStorage.getItem("config");
  const getconfig = JSON.parse(getconfigdata);
  let webrtcdomain = getconfig.projects[0].config[0].webrtc_domain;
  let webrtc_ip = getconfig.projects[0].config[0].webrtc_ip;
  let webrtc_station = getconfig.projects[0].userInfo[0].user_phone_number;
  let user_id = getconfig.projects[0].userInfo[0].user_id;

  const webrtc = async () => {
    localStorage.setItem("resume", JSON.stringify(true));
    let webrtcSocket = new JsSIP.WebSocketInterface(`wss://${webrtcdomain}/ws`);
    var configuration = {
      sockets: [webrtcSocket],
      display_name: webrtc_station,
      authorization_user: webrtc_station,
      uri: `sip:${webrtc_station}@${webrtc_ip}`,
      password: webrtc_station,
      realm: webrtc_ip,
      DtlsSrtpKeyAgreement: false,
      contact_uri: `sip:${webrtc_station}@${webrtc_ip}`,
      connection_recovery_min_interval: 5,
      connection_recovery_max_interval: 20,
      use_preloaded_route: true,
      session_timers: false,
      no_answer_timeout: 60000,
      register_expires: 30000000,
      ws_servers: `wss://${webrtc_ip}/ws`,
      trace_sip: true,
    };
    var coolPhone = new JsSIP.UA(configuration);

    coolPhone.start();
    setPhone(coolPhone);

    coolPhone.register();

    coolPhone.on("registered", function (e) {
      console.log(e, "registered", "============>");
      localStorage.setItem("sipstatus", true);
    });

    coolPhone.on("unregistered", function (e) {
      console.log(e, "unregistered", "============>");
    });

    coolPhone.on("registrationFailed", function (e) {
      console.log(e, "registrationFailed", "============>");
    });

    coolPhone.on("connected", function (e) {
      console.log(e, "connected", "============>");

      setBadge("Online");
    });

    coolPhone.on("disconnected", function (e) {
      // toast.error('Sip Disconnected');
      console.log(e, "disconnected", "============>");
    });

    coolPhone.on("newRTCSession", function (e) {
      const session = e.session;
      dispatch(setSession(session));

      session.on("ended", function (data) {
        console.log("490===============>",config_api.projects[0].userPermission.auto_dispo);
        
        if (config_api.projects[0].userPermission.auto_dispo === true) {
          handleSubmit();
          dispatch(setshowcalllogpage(true));
          localStorage.removeItem("livecall");
        } else if (config_api.projects[0].userPermission.auto_dispo === false) {
          localStorage.setItem("livecall", false);
        }
        setis_incoming(false);

        dispatch(rtcprocess(false));
        dispatch(rtcended(true));

        setCallended(false);
        setWebphone(false);
        setCallStatus("ended");
        setLeadName(leadname);
        setcallername(callername);
        setStarttimer(false);
        // setIncomingdetails(false)
        resetCallDuration();
        console.log("session Ended==============>", data);
      });
      session.on("hold", function (data) {
        // The call has been placed on hold
      });

      session.on("failed", function (data) {
        console.log("Failed==========>", data);
        ringtoneRef.current.pause();
        ringtoneRef.current.currentTime = 0;
        // dispatch(rtcprocess(false))
        // dispatch(rtcended(true))

        dispatch(setshowcalllogpage(true));
        dispatch(setagentstatus(false));
        dispatch(AutoDialCurrent({}));
        setis_incoming(false);

        setIncomingscreen(false);
        setIncomingdetails(false);
        dispatch(rtcended(false));
        // setInboundcall(false)
        setLeadName(leadname);
        resetCallDuration();
        setWebphone(false);
        let customer_info = {
          response_status: "",
          customer_name: "",
          customer_number: "",
          module: "",
          customer_id: "",
          customer_leadstatus: "",
          customer_leadowner: "",
          call_type: "",
          call_outcome: ""
        };
        dispatch(setcutomerinfo(customer_info));
        localStorage.removeItem("customer_info");
        localStorage.removeItem("livecall");
      });

      setResponse(session);
      // dispatch(setSession(session));
      session.on("progress", function (e) {
        console.log("Progress...", session._request.headers);
        localStorage.setItem("livecall", true);
        dispatch(rtcprocess(true));
        dispatch(setshowcalllogpage(false));
        setShowDialpad(false);
        dispatch(setagentstatus(true)); //based on oncall notshow the icon
        // setWebphone(true)
        dispatch(rtcended(false));
        setStarttimer(true);
        startCallDuration();
        setCallended(true);

        // let callee_number = session._request.from.uri.user;
        // setCallStatus('oncall');
        const headers = session._request.headers;
        console.log("Inboundnumber", session._request.from.uri.user);

        // const call_type = headers['Call-Info'][0].raw
        const call_type = headers["Call-Info"]
          ? headers["Call-Info"][0].raw
          : "";
        const ib_queuename = headers["Call-Queue"]
          ? headers["Call-Queue"][0].raw
          : "";
        console.log("Inbound queue name====>", ib_queuename);
        const cleanCallType = call_type.substring(3);
        const outbount_type = call_type.substring(16);
        if (cleanCallType === "inbound") {
          handlechangebarmenu(undefined, "1");
          setInboundcall(true);
          setWebphone(false);
          ringtoneRef.current.loop = true;
          ringtoneRef.current.play();
          let callee_number = session._request.from.uri.user;
          setCallernumber(callee_number);
          const data = {
            type: "Ringing",
            phone_number: callee_number,
          };
          console.log("Incoming call postmessage", data);

          window.parent.postMessage(data, "*");
          let CustomerInfopayload = {
            phone_number: callee_number,
          };

          if (Notification.permission === "granted") {
            new Notification("Incoming call", {
              body: `Incoming call from ${callee_number}`,
              icon: favicon, // Optional: You can add an icon for the notification
            });
          }
          dispatch(CustomerInfo(CustomerInfopayload))
            .then((response) => {
              if (response.status == 200) {
                let customer_info = {
                  response_status: response.status,
                  customer_name: response.data.data[0].customerName,
                  customer_number: callee_number,
                  module: response.data.module,
                  customer_id: response.data.data[0].customerID,
                  customer_leadstatus: response.data.data[0].leadStatus,
                  customer_leadowner: response.data.data[0].ownerName,
                  call_type: "Inbound",
                  call_outcome: "inbound",
                  queue_name: ib_queuename,
                };
                dispatch(setcutomerinfo(customer_info));
                localStorage.setItem(
                  "customer_info",
                  JSON.stringify(customer_info)
                );
              } else {
                let customer_info = {
                  response_status: response.status,
                  customer_name: "UNKNOWN NUMBER",
                  customer_number: callee_number,
                  call_type: "Inbound",
                  call_outcome: "inbound",
                  queue_name: ib_queuename,
                };

                dispatch(setcutomerinfo(customer_info));
                localStorage.setItem(
                  "customer_info",
                  JSON.stringify(customer_info)
                );
              }
            })
            .catch((err) => {
              return err;
            });

          setIncomingscreen(true);
        }
        if (outbount_type === "manual") {
          setInboundcall(false);
          setWebphone(true);
          setTimeout(function () {
            session.answer(sipOptions);
          }, 500);
          setWebphone(true);
        } else {
          setis_incoming(true);
          setIncomingscreen(true);

          console.log("Auto Answer Failed");
        }

        //  localStorage.setItem('setCallended',false)
      });

      // Determine if it's an incoming or outgoing call
      if (session.direction === "outgoing") {
        // Handle outgoing call

        console.log("Outgoing call initiated to:");
      } else if (session.direction === "incoming") {
        console.log("session.direction======================>", session);
        //let callee_number = session._request.from.uri.user;
        setCallStatus("oncall");
        //const headers = session._request.headers;
        //const call_type = headers['Call-Info'][0].raw
        //const ib_queuename = headers['Call-Queue']?headers['Call-Queue'][0].raw :'';
        //console.log("Inbound queue name====>",ib_queuename);
        //const cleanCallType = call_type.substring(3);
        //const outbount_type = call_type.substring(16);
        // if (cleanCallType === 'inbound') {
        //     handlechangebarmenu(undefined, "1")
        //     setInboundcall(true)
        //     setWebphone(false)
        //     ringtoneRef.current.loop = true;
        //     ringtoneRef.current.play();
        //     setCallernumber(callee_number)
        //     const data = {
        //         type: "Ringing"
        //     }
        //     window.parent.postMessage(data, '*');
        //     let CustomerInfopayload = {
        //         phone_number: callee_number
        //     }

        //     if (Notification.permission === "granted") {
        //         new Notification("Incoming call", {
        //             body: `Incoming call from ${callee_number}`,
        //             icon: favicon // Optional: You can add an icon for the notification
        //         });
        //     }
        //     dispatch(CustomerInfo(CustomerInfopayload)).then((response) => {
        //         if (response.status == 200) {
        //             let customer_info = {
        //                 response_status: response.status,
        //                 customer_name: response.data.data[0].customerName,
        //                 customer_number: callee_number,
        //                 module: response.data.module,
        //                 customer_id: response.data.data[0].customerID,
        //                 customer_leadstatus: response.data.data[0].status,
        //                 customer_leadowner: response.data.data[0].ownerName,
        //                 call_type: "Inbound",
        //                 queue_name:ib_queuename
        //             }
        //             dispatch(setcutomerinfo(customer_info))
        //             localStorage.setItem('customer_info', JSON.stringify(customer_info))
        //             setIncomingscreen(true)

        //         } else {
        //             let customer_info = {
        //                 response_status: response.status,
        //                 customer_name: "UNKNOWN NUMBER",
        //                 customer_number: callee_number,
        //                 call_type: "Inbound",
        //                 queue_name:ib_queuename
        //             }

        //             dispatch(setcutomerinfo(customer_info))
        //             localStorage.setItem('customer_info', JSON.stringify(customer_info))

        //         }

        //     }).catch((err) => {
        //         return err
        //     })

        // }
        // if (outbount_type === 'manual') {
        //     setInboundcall(false)
        //     setWebphone(true)
        //     setTimeout(function () {
        //         session.answer(sipOptions)
        //     }, 500)

        // }
        // else {
        //     setis_incoming(true)
        //     setIncomingscreen(true)

        //     console.log("Auto Answer Failed");

        // }

        session.on("peerconnection", function (data) {
          data.peerconnection.addEventListener("addstream", function (e) {
            let remoteAudio = document.createElement("audio");
            remoteAudio.srcObject = e.stream;
            remoteAudio.play();

            // sound(false)
          });
        });
      }
    });
  };

  const startCallDuration = () => {
    if (!callStartTime) {
      setCallStartTime(new Date());
    }
  };
  const resetCallDuration = () => {
    setCallStartTime(null);
    setCallDuration(0);
  };

  useEffect(() => {
    if (Notification.permission !== "granted") {
      Notification.requestPermission();
    }
    window.addEventListener("message", incomingresponse);
  }, []);

  const incomingresponse = async (event) => {
    let inboundlead_name = event.data.lead_name;
    if (inboundlead_name) {
      // setcallername(inboundlead_name)
      setLeadName(inboundlead_name);
      setcallername(inboundlead_name);
    }
  };
  // useEffect(() => {
  //     fetchDispo();
  // }, [])

  useEffect(() => {
    const crmname = localStorage.getItem("config");
    const crmowner_name = JSON.parse(crmname).projects[0].userInfo[0].user_name;
    if (callername == "" || callername == null || callername == undefined) {
      setcallername("Unkown Name");
    }
    setcrmownername(crmowner_name);
    if (callStartTime) {
      const timer = setInterval(() => {
        const currentTime = new Date();
        const durationInSeconds = Math.floor(
          (currentTime - callStartTime) / 1000
        );
        setCallDuration(durationInSeconds);
      }, 1000);

      return () => {
        clearInterval(timer);
      };
    }
  }, [callStartTime]);

  useEffect(() => {
    const debouncedHandleMessage = _.debounce(handleMessage, 1000); // Adjust the delay as needed

    window.addEventListener("message", debouncedHandleMessage);

    return () => {
      window.removeEventListener("message", debouncedHandleMessage);
    };
  }, []); // Empty dependency array means this runs once on mount and cleanup on unmount

  const CrmConvertDatas = (data) => {
  
    let parsedData = JSON.parse(data);

    let customer_data = {
      customer_name: parsedData.recordTitle,
      customer_number: parsedData.value,
      customer_id: parsedData.entityId,
      module: parsedData.entityLogicalName,

      call_type: "Outbound",
      call_outcome: "manual",
      type: "C2C",
    };
    return customer_data;
  };

  const handleMessage = async (event) => {
    try {
      console.log("C2C Event Data:", event.data);

      handlechangebarmenu(undefined, "1");

      let entity_number;
      let lead_id1;
      let customer_info = {};

      const api_domain = JSON.parse(localStorage.getItem("token"));
      const apiToken = api_domain.access_token;
      const stationnumber = webrtc_station;
      const module = "Leads";
      // entity_number = event.data.data.number ||event.data.data.entity_number;

      if (event.data.type == "Manual") {
        //freshdesk //Manual

        entity_number = event.data.data.number;
        lead_id1 = event.data.data.id;

        let CustomerInfopayload = {
          phone_number: entity_number,
        };
        dispatch(CustomerInfo(CustomerInfopayload))
          .then((response) => {
            let customer_info;
            if (response.status == 200) {
              customer_info = {
                response_status: response.status,
                customer_name: response.data.data[0].customerName,
                customer_number: entity_number,
                module: response.data.module,
                customer_id: response.data.data[0].customerID,
                // customer_leadstatus: response.data.data[0].status,
                // customer_leadowner: response.data.data[0].ownerName,
                call_type: "Outbound",
                call_outcome: "manual",
              };
              dispatch(setcutomerinfo(customer_info));
              localStorage.setItem(
                "customer_info",
                JSON.stringify(customer_info)
              );
              makeACall(entity_number,stationnumber,lead_id1,customer_info.customer_name)
            } else {
              if(config_api.projects[0].userPermission.auto_lead_create){
                const createpayload = {
                  module: "Leads",
                  payload: {
                    owner_name: user_id,
                    phone_number: entity_number,
                  },
                };
  
                dispatch(Createcrm(createpayload))
                  .then((createresponse) => {
                    if (createresponse.status === 201) {
                      let customer_info = {
                        response_status: createresponse.status,
                        customer_name: createresponse.data.data.customerName,
                        customer_number: entity_number,
                        module: createresponse.data.module,
                        customer_id: createresponse.data.data.customerID,
                        customer_leadstatus: createresponse.data.data.callStatus,
                        customer_leadowner: createresponse.data.data.ownerName,
                        call_type: "Outbound",
                        call_outcome: "manual",
                      };
                      dispatch(setcutomerinfo(customer_info));
                      localStorage.setItem(
                        "customer_info",
                        JSON.stringify(customer_info)
                      );
                      makeACall(entity_number,stationnumber,lead_id1,customer_info.customer_name)
                    }
                  })
                  .catch((err) => {
                    toast.error("Lead not created");
                    console.log(err);
                    return err;
                  });
              }else{
                let customer_info = {
                  customer_name: "UNKNOWN NUMBER",
                  customer_number: entity_number,
                  call_type: "Outbound",
                  call_outcome: "manual",
                  type: "C2C",
                };

                dispatch(setcutomerinfo(customer_info));
                      localStorage.setItem(
                        "customer_info",
                        JSON.stringify(customer_info)
                );
                makeACall(entity_number,stationnumber,lead_id1,customer_info.customer_name)
              }
            }
          })
          .catch((err) => {
            return err;
          });
      } else if (event.data.messageType === "onclicktoact") {
       

        let customer_info = CrmConvertDatas(event.data.messageData);
        entity_number = customer_info.customer_number;
        dispatch(setcutomerinfo(customer_info));
        localStorage.setItem("customer_info", JSON.stringify(customer_info));
        makeACall(entity_number,stationnumber,lead_id1,customer_info.customer_name)
      } else {
        if (event.data.data.customer_number) {
          entity_number = event.data.data.customer_number;
          lead_id1 = event.data.data.customer_id;

          let CustomerInfopayload = {
            phone_number: entity_number,
          };

          dispatch(CustomerInfo(CustomerInfopayload))
            .then((response) => {
              if (response.status == 200) {
                let customer_info = {
                  customer_name: response.data.data[0].customerName,
                  customer_number: entity_number,
                  module: event.data.data.module,
                  customer_id: event.data.data.customer_id,
                  call_type: "Outbound",
                  call_outcome: "manual",
                  type: "C2C",
                };
                dispatch(setcutomerinfo(customer_info));
                localStorage.setItem(
                  "customer_info",
                  JSON.stringify(customer_info)
                );
                makeACall(entity_number,stationnumber,lead_id1,customer_info.customer_name)
              } else {
                let customer_info = {
                  customer_name: "UNKNOWN NUMBER",
                  customer_number: entity_number,
                  call_type: "Outbound",
                  call_outcome: "manual",
                  type: "C2C",
                };

                dispatch(setcutomerinfo(customer_info));
                localStorage.setItem(
                  "customer_info",
                  JSON.stringify(customer_info)
                );
                makeACall(entity_number,stationnumber,lead_id1,customer_info.customer_name)
              }
            })
            .catch((err) => {
              return err;
            });
        } else {//custom crm
          lead_id1 = event.data.data.customer_id;
          entity_number = event.data.data.entity_number;
          let customer_info = {
            customer_name: event.data.data.entity_name,
            customer_number: entity_number,
            module: event.data.data.entity_type || "",
            customer_id: event.data.data.entity_id,
            call_type: "Outbound",
            call_outcome: "manual",
            type: "C2C",
          };
          dispatch(setcutomerinfo(customer_info));
          localStorage.setItem("customer_info", JSON.stringify(customer_info));
          makeACall(entity_number,stationnumber,lead_id1,customer_info.customer_name);
        }
      }

      
    } catch (error) {
      console.error("Error making call:", error);
    }
  };

  const makeACall = (entity_number,stationnumber,lead_id1,customer_name)=>{
    if (entity_number !== null &&entity_number !== undefined && localStorage.getItem("liveagentdata") == "READY")
      {
     const payload = {
       station: stationnumber,
       phone_number: entity_number,
       caller_name:customer_name,
       agent: {
         identity: "user",
         value: user_name1,
       },
       custdata: {
         lead_id: lead_id1,
         module: module,
       },
     };

     dispatch(MakeManualcall(payload))
       .then((callresponse) => {
         if (callresponse.status === 200) {
           localStorage.setItem(
             "callstatus",
             JSON.stringify(response.status)
           );

           //SetAutodialflag(false)
           // setWebphone(true)
         } else {
           setPause(true);
         }
       })
       .catch((err) => {
         if (err.response.status == 401) {
           localStorage.removeItem("login");
           localStorage.removeItem("hanguppayload");
           localStorage.removeItem("resume");
           localStorage.removeItem("agentstatus");
           localStorage.removeItem("config");
           localStorage.removeItem("username");
           localStorage.removeItem("apinfo");
           localStorage.removeItem("token");
           localStorage.removeItem("callstatus");
           localStorage.removeItem("queuelogin");
           //localStorage.removeItem('crmuserinfo');
           localStorage.removeItem("entititytype");
           localStorage.removeItem("station");
           localStorage.removeItem("livecall");
           localStorage.removeItem("callstatus");
           window.location.href = `/`;
         } else {
           setPause(true);
         }
         localStorage.setItem(
           "callstatus",
           JSON.stringify(err.response.status)
         );
       });
   }else if(localStorage.getItem("liveagentdata") == "PAUSE" || localStorage.getItem("liveagentdata") == "CONNECT" || localStorage.getItem("liveagentdata") == "RINGING"  ){
     setPause(true);
   }
  }

  const answercall = async () => {
    response.answer(sipOptions);
    setIncomingdetails(true);
    setIncomingscreen(false);
    setWebphone(true);

    if (
      config_api.projects[0].userPermission.auto_lead_create &&
      customer_info_state.customer_name == "UNKNOWN NUMBER"
    ) {
      const createpayload = {
        module: "Leads",
        payload: {
          owner_name: user_id,
          phone_number: callernumber,
        },
      };

      dispatch(Createcrm(createpayload))
        .then((createresponse) => {
          if (createresponse.status === 201) {
            let customer_info = {
              response_status: createresponse.status,
              customer_name: createresponse.data.data.customerName,
              customer_number: callernumber,
              module: createresponse.data.module,
              customer_id: createresponse.data.data.customerID,
              customer_leadstatus: createresponse.data.data.callStatus,
              customer_leadowner: createresponse.data.data.ownerName,
              call_type: "Inbound",
              call_outcome: "manual",
            };
            dispatch(setcutomerinfo(customer_info));
            localStorage.setItem(
              "customer_info",
              JSON.stringify(customer_info)
            );
          }
        })
        .catch((err) => {
          return err;
        });
    }

    ringtoneRef.current.pause();
    ringtoneRef.current.currentTime = 0;
  };

  const declinecall = async () => {
    response.terminate(sipOptions);
    // response.terminate({
    //     status_code: 603,
    //     reason_phrase: 'Decline',
    // });
    ringtoneRef.current.pause();
    ringtoneRef.current.currentTime = 0;
    let customer_info = {
      response_status: "",
      customer_name: "",
      customer_number: "",
      module: "",
      customer_id: "",
      customer_leadstatus: "",
      customer_leadowner: "",
      call_type: "",
      call_outcome: "",
    };
    dispatch(setcutomerinfo(customer_info));
    localStorage.removeItem("customer_info");
  };

  function toggleUserOptions() {
    const userOptions = document.getElementById("userOptions");
    userOptions.style.display =
      userOptions.style.display === "block" ? "none" : "block";
  }
  function logout() {
    try {
      const logutpayload = {
        user: user_name1,
        station: station,
      };
      // dispatch(Logout(logutpayload))

      dispatch(Logout(logutpayload))
        .then((response) => {
          if (response.status === 200) {
            phone.unregister();

            forcelogout();
            // .then(() => {
            //   // Refresh the window after navigating
            //   window.location.reload();
            // })
            // .catch((err) => {
            //   return err;
            // });
          }
        })
        .catch((err) => {
          return err;
        });
    } catch (error) {
      return error;
    }
  }

  const handlechangebarmenu = (event, newValue) => {
    if (newValue == "2") {
      dispatch(setshowcalllogpage(false));
      dispatch(rtcprocess(true));
    } else if (newValue == "3") {
      dispatch(setshowcalllogpage(false));

      setShowDialpad(true);
    } else {
      dispatch(setshowcalllogpage(true));
      // dispatch(rtcprocess(false))
      localStorage.setItem("Showautodialpageui", false);
    }
    setbarmenuvalue(newValue);
  };

  function HandleSetBadge(badge) {
    const userOptions = document.getElementById("userOptions");
    userOptions.style.display = "none";
    if (badge == "Online") {
      setBadge("Online");
      setPause(false);
      const agentname = localStorage.getItem("username");
      const payload = {
        user: agentname,
      };
      dispatch(Resume(payload))
        .then((resumeresponse) => {
          if (resumeresponse.status == 200) {
            localStorage.setItem("agentstatus", true);
            setAwayOptionsOpen(false);
            webrtc();
          }
        })
        .catch((err) => {
          // if (err.response.status == 400) {
          //     // forcelogout()
          // }
          return err;
        });
    } else if (badge == "Away") {
      setBadge("Away");
      setAwayOptionsOpen(true);
    } else {
      setAwayOptionsOpen(false);
      const agentname = localStorage.getItem("username");
      const payload = {
        user: agentname,
        reason: badge,
      };
      dispatch(Pause(payload))
        .then((pauseresponse) => {
          if (pauseresponse.status == 200) {
            localStorage.setItem("agentstatus", false);
            setAwayOptionsOpen(true);
            unregisterWebRTC();
          }
        })
        .catch((err) => {
          // if (err.response.status == 400) {
          //     forcelogout()
          // }
          return err;
        });
    }
  }
  return (
    <div>
      {webphone ? (
        <Webphone is_incoming={is_incoming} />
      ) : pause ? (
        <div>
          <Comtemplete
            toggleUserOptions={toggleUserOptions}
            badge={badge}
            logout={logout}
            handleSetBadge={HandleSetBadge}
            awayOptionsOpen={awayOptionsOpen}
          >
            <div id="top" class="page-wrap">
              <div className="agent-pause">
                <img class="pause-illust" src={pausewave}></img>
                <div className="pause-notes">User is offline</div>
              </div>
            </div>
          </Comtemplete>
        </div>
      ) : incomingscreen ? (
        <div>
          <Incomingcallscreen
            onAnswer={answercall}
            onDecline={declinecall}
            leadname={callername}
            leadnumber={callernumber}
            leaddate={ibleaddate}
            leadowner={"Pandi"}
          />
        </div>
      ) : livecall_status == false ? (
        <div>
          <Disposcreen action={action} />
        </div>
      ) : backgroundstate.setshowcalllogpage ? (
        <div>
          <Comtemplete
            toggleUserOptions={toggleUserOptions}
            badge={badge}
            logout={logout}
            handleSetBadge={HandleSetBadge}
            awayOptionsOpen={awayOptionsOpen}
          >
            <div id="top" class="page-wrap">
              <CallLog />
            </div>
          </Comtemplete>
        </div>
      ) : showDialpad && barmenuvalue == "3" ? (
        <div>
          <Comtemplete
            toggleUserOptions={toggleUserOptions}
            badge={badge}
            logout={logout}
            handleSetBadge={HandleSetBadge}
            awayOptionsOpen={awayOptionsOpen}
          >
            <Dialpad />
          </Comtemplete>
        </div>
      ) : (
        <div></div>
      )}
      {/* Bottom Navigation */}
      {barmenuvalue == "2" && backgroundstate.rtcprocess && !webphone && (
        <Comtemplete
          toggleUserOptions={toggleUserOptions}
          badge={badge}
          logout={logout}
          handleSetBadge={HandleSetBadge}
          awayOptionsOpen={awayOptionsOpen}
        >
          <Autodial action={action} Autodialflag={Autodialflag} />
        </Comtemplete>
      )}
      {/* {barmenuvalue == "3" && backgroundstate.rtcprocess && !webphone && <Dashboard/>} */}
      {liveagentdata !== "RINGING" &&
        liveagentdata !== "CONNECT" &&
        liveagentdata !== "DISPO" && (
          <div className="bottom_navigation">
            <BottomNavigation
              showLabels
              onChange={handlechangebarmenu}
              value={barmenuvalue}
            >
              <BottomNavigationAction
                label="Home"
                value="1"
                icon={<FontAwesomeIcon icon={faHouse} />}
              />
              <BottomNavigationAction
                label="Dialpad"
                value="3"
                icon={<FontAwesomeIcon icon={faKeyboard} />}
              />
              {config_api.projects[0].userPermission.auto_dial && (
                <BottomNavigationAction
                  label="Lead Dial"
                  value="2"
                  icon={<FontAwesomeIcon icon={faUser} />}
                />
              )}

              {/* <BottomNavigationAction label="Dashboard" value="3" icon={<FontAwesomeIcon icon={faHouse} />} />
               */}
            </BottomNavigation>
          </div>
        )}
    </div>
  );
}

export default Homepage;
