// sessionActions.js

export const setSession = (session) => ({
    type: 'SET_SESSION',
    payload: session,
  });


  
 
  