import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "../assets/css/autodialpage.css";
import {
  faPlayCircle,
  faSearch,
  faPause,
  faCircleStop,
  faThumbsUp,
  faBan,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Webphone from "../components/webphone";
import { setcutomerinfo } from "../redux/leadinfo/customerinfoaction";
import {
  DiallerLeadFetch,
  getLeadstatus,
  MakeManualcall,
  FetchDispo,
  FetchDialerModule,
  FetchCustomview,
} from "../redux/apicalling/dispatchfunction";
import Select from "react-select";
import nodataimg from "../assets/images/nodataclippad.svg";
import dooctiloader from "../assets/images/Doocti_brand_loader.gif";
import diallerwaitingimg from "../assets/images/dialler_waiting.svg";
import { setleadstatus } from "../redux/leadstatus/leadstatusaction";
// import { Autolead, AutoDialbtn, AutoDialStatus, AutoDialCurrent } from '../redux/AutoLeadstatus/LeadstatusAction'
import {
  Autolead,
  AutoDialbtn,
  AutoDialStatus,
  AutoDialCurrent,
  AutoDialSubfilter1,
  AutoDialSubfilter2,
  AutoDialPagecount,
  CustomViewvalue,
  AutoDialSkipcount,
  AutoDialTakecount
} from "../redux/autodial/autodialaction";
import { toast } from "react-toastify";
import person from "../assets/images/person.svg";

function Autodial(props) {
  let { action, Autodialflag } = props;
  const dispatch = useDispatch();
  const autoleads = useSelector((state) => state.autoleads);
  
  

  const setplaybtn_flag = JSON.parse(localStorage.getItem("setplaybtnflag"));

  const backgroundstate = useSelector((state) => state.bgpermission);

  const handleMessage = async (event) => {
    try {
      if (event.data.type === "LeadStatus") {
        dispatch(setleadstatus(event.data.data));
      }
    } catch (error) {
      return error;
    }
  };

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 2;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 200,
      },
    },
  };
  const [auto_lead_status, setAutoleadstatus] = useState([]);
  const [auto_call_status_dropdown, setAutocallstatusdropdown] = useState([]);
  const lstatus_dropdown = auto_lead_status.map((status) => status.NAME);
  const [entitytype, setEntitytype] = useState([]);
  const [customviewoption, setCustomviewoption] = useState([]);

  const [leadstatus, setLeadstatus] = useState([]);

  const [callstatus, setCallstatus] = useState();
  const [autodialwebphone, setAutodialwebphone] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState("leads");
  const [lstatusselctvalue, setLstatusselectvalue] = useState([]);
  const [customviewvalue, setCustomviewValue] = useState([]);
  const [callstatusselctvalue, setCallstatusselectvalue] = useState([]);
  const [lstatusselctvalue_slt, setLstatusselectvalue_slt] = useState(null);
  const [customviewvalue_list, setCustomviewvalue_list] = useState(null);

  const [entitytypevalue, setEntitytypevalue] = useState({label: 'Leads', value: 'Leads'});
  const [callstatusselctvalue_slt, setCallstatusselectvalue_slt] =
    useState(null);
  const callstatusOptions = ["Sms", "WhatsApp", "Facebook", "Instagram"];
  let [autodialtimer, setautodialtimer] = useState(0);
  let [autodialtimerstatus, setautodialtimerstatus] = useState(false);
  const dialer_interval = JSON.parse(localStorage.getItem("config")).projects[0]
    .config[0].dialer_interval;
  const config_api = JSON.parse(localStorage.getItem("config"));
  let {dialer_subFilter1,dialer_subFilter2,crm_call_purpose} = config_api.projects[0].userPermission;
  const [leadsData, setLeadsData] = useState([]);
  const [selectedLeads, setSelectedLeads] = useState([]);
  const [isPlayClicked, setIsPlayClicked] = useState(false);
  const [loader, setLoader] = useState(false);
  const [inputValue, setInputValue] = useState(50);
  const [skipValue,setSkipValue] =useState("")
  const [pagecountflat, setPagecountflag] = useState(false);
  let dialler_type = localStorage.getItem('dialer_type')
 
  useEffect(() => {
    window.addEventListener("message", handleMessage);
    if (autoleads.autodialplayparsebtn) {
      setautodialtimerstatus(true);
    }
    return () => {
      window.removeEventListener("message", handleMessage);
    };
  }, []);

  useEffect(() => {
    if (Autodialflag == false) {
      handleResetClick();
    }
  }, [Autodialflag]);

  useEffect(() => {

    
    let _Interval_timer = null;
    let _Timeout_timer = null;

    if (Autodialflag) {
      if (autoleads.AutoDialSubfilter1.length > 0) {
        setLstatusselectvalue_slt({
          label: autoleads.AutoDialSubfilter1[0],
          value: autoleads.AutoDialSubfilter1[0],
        });
        setLstatusselectvalue(autoleads.AutoDialSubfilter1);
      }

      if (autoleads.AutoDialSubfilter2.length > 0) {
        setCallstatusselectvalue_slt({
          label: autoleads.AutoDialSubfilter2[0],
          value: autoleads.AutoDialSubfilter2[0],
        });
        setCallstatusselectvalue(autoleads.AutoDialSubfilter2);
      }

      if (autoleads.CustomViewvalue.length > 0) {
        // setCustomviewvalue_list({
        //   label: autoleads.AutoDialSubfilter2[0],
        //   value: autoleads.AutoDialSubfilter2[0],
        // });
        setCustomviewValue(autoleads.CustomViewvalue)
      }

     
      

      let timer = dialer_interval;
      if (autoleads.autodialeads.length > 0) {
        // localStorage.setItem('setplaybtnflag', true)
        setLeadsData(autoleads.autodialeads);
        setSelectedLeads(autoleads.autodialeads);
        if (autoleads.autodialplayparsebtn && autodialtimerstatus) {
          setIsPlayClicked(true);
          if (autodialtimer == 0) {
            setautodialtimer(timer);
          } else {
            timer = autodialtimer;
          }
          _Interval_timer = setInterval(function () {
            setautodialtimer((set) => {
              return set - 1;
            });
          }, 1000);
          _Timeout_timer = setTimeout(function () {
            let payload = {
              phone_number: autoleads.autodialeads[0].customerNumber,
              id: autoleads.autodialeads[0].customerID,
              module: "leads",
              name: autoleads.autodialeads[0].customerName,
            };
            dispatch(AutoDialCurrent(payload));
            initiateCall(payload);
            let customer_info = {
              customer_name: autoleads.autodialeads[0].customerName,
              customer_number: autoleads.autodialeads[0].customerNumber,
              module: leadsData[0].module,
              customer_id: autoleads.autodialeads[0].customerID,
              call_outcome: "auto",
              call_type: "Outbound",
              type: "AUTO",
              lead_view:autoleads.CustomViewvalue[0]?.label
            };

            dispatch(setcutomerinfo(customer_info));
            // localStorage.setItem("customer_info", customer_info);
            localStorage.setItem(
              "customer_info",
              JSON.stringify(customer_info)
            );
          }, timer * 1000);
        } else {
          clearInterval(_Interval_timer);
          clearTimeout(_Timeout_timer);
        }
      } else {
        // localStorage.setItem('setplaybtnflag', true)
        if (setplaybtn_flag) {
          localStorage.setItem("setplaybtnflag", true);
        } else {
          localStorage.setItem("setplaybtnflag", false);
          handleResetClick();
        }
        //  handleResetClick()
      }
    }

    return () => {
      clearInterval(_Interval_timer);
      clearTimeout(_Timeout_timer);
    };
  }, [autodialtimerstatus]);

  const reset = () => {
    dispatch(AutoDialStatus(false));
    setLstatusselectvalue([])
    setCustomviewValue([])
    setCallstatusselectvalue([])
    setLstatusselectvalue_slt(null)
    setCustomviewvalue_list(null)
    setInputValue(50)
    setEntitytypevalue({label: 'Leads', value: 'Leads'});
  }

  useEffect(() => {
    fetchDispo();
    fetchLeadstaus();
    fetchmodulevaluedropdown();
    fetchcustomview();
    setFormValue()
  }, []);

  const setFormValue = () =>{
   const resetForm = JSON.parse(localStorage.getItem("resetForm"));
   if(resetForm){
   if(resetForm.entitytype){
    setEntitytypevalue(resetForm.entitytype);
   }else{
    setEntitytypevalue({label: 'Leads', value: 'Leads'});
   }

   if(resetForm.customView){
    setCustomviewvalue_list(resetForm.customView);
    setCustomviewValue([resetForm.customView]);
   }else{
    setCustomviewvalue_list(null);
    setCustomviewValue([]);
   }

   if(resetForm.leadStatus){
    setLstatusselectvalue_slt(resetForm.leadStatus)
    dispatch(AutoDialSubfilter1([resetForm.leadStatus.value]));
    setLstatusselectvalue([resetForm.leadStatus.value]);
   }else{
    setLstatusselectvalue_slt(null)
    dispatch(AutoDialSubfilter1([]));
    setLstatusselectvalue([]);
   }

   if(resetForm.callstatus){
    setCallstatusselectvalue([resetForm.callstatus.value]);
    setCallstatusselectvalue_slt(resetForm.callstatus);
    dispatch(AutoDialSubfilter2([resetForm.callstatus.value]));
   }else{
    setCallstatusselectvalue([]);
    setCallstatusselectvalue_slt(null);
    dispatch(AutoDialSubfilter2([]));
   }

   if(resetForm.limit){
    setInputValue(resetForm.limit);
   }else{
    setInputValue(50);
   }
  }
  }


  const fetchmodulevaluedropdown = async () => {
    dispatch(FetchDialerModule())
      .then((response) => {
        if (response.status === 200) {
          setEntitytype(response.data.data);
        }
      })
      .catch((err) => {
        return err;
      });
  };

  const fetchcustomview = async () => {
    dispatch(FetchCustomview()).then((response) => {
      if (response.status === 200) {
        setCustomviewoption(response.data.data);
      }
    });
  };

  const fetchLeadstaus = async () => {
    dispatch(getLeadstatus())
      .then((response) => {
        if (response.status === 200) {
          setLeadstatus(response.data.data);
        }
      })
      .catch((err) => {
        return err;
      });
  };

  const fetchDispo = async () => {
    dispatch(FetchDispo(crm_call_purpose))
      .then((response) => {
        if (response.status === 200) {
          setCallstatus(response.data.data);
        }
      })
      .catch((err) => {
        return err;
      });
  };

 

  

  

  const handleSearchClick = (data) => {
  
    
    localStorage.setItem('dialer_type',entitytypevalue.value)
    
    
    setSkipValue(data)
    setLoader(true);
    if (data > 1 && pagecountflat) {
      dispatch(AutoDialPagecount(1));
    } else {
      dispatch(AutoDialPagecount(data));
    }

    dispatch(AutoDialSubfilter1(lstatusselctvalue));
    dispatch(AutoDialSubfilter2(callstatusselctvalue));
    dispatch(CustomViewvalue(customviewvalue));
    const leadfetchpayload = {
      module: dialler_type,
      page: autoleads.AutoDialPagecount,
      take: autoleads.AutoDialTakecount,
      skip: autoleads.AutoDialSkipcount,
      filter: {
        Owner: config_api.projects[0].userInfo[0].user_id,
      },
    };

    if (lstatusselctvalue.length > 0) {
      leadfetchpayload.filter.Lead_Status = lstatusselctvalue[0];
    }

    if (callstatusselctvalue.length > 0) {
      leadfetchpayload.filter.Call_Status = callstatusselctvalue[0];
    }
    
    if (
      (customviewvalue[0] !== null &&
        customviewvalue[0] !== undefined &&
        customviewvalue[0] !== "") ||
      autoleads.CustomViewvalue.length > 0
    ) {
      leadfetchpayload.filter.customviewvalue =
        customviewvalue[0].value || autoleads.CustomViewvalue.value;
    }

    dispatch(DiallerLeadFetch(leadfetchpayload))
      .then((response) => {
        if (response.status === 200) {
          if (
            response?.data?.statusCode === 200 &&
            response?.data?.data.length > 0
          ) {
           
            // setLeadsData(response.data.data);
            const filteredData = response.data.data.filter(
              (item) => item.customerNumber !== null
            );
            setLeadsData(filteredData);
            
            
            setLoader(false);
          }

          if (response?.data?.statusCode === 204) {
            setLeadsData([]);
            setLoader(false);
          }
        } else {
          localStorage.setItem("setplaybtnflag", false);
          handleResetClick();

          setLoader(false);
          //no data (204)
        }
      })
      .catch((err) => {
        return err;
      });
  };
  const diallercontinue = () => {
    
    const dialer_skipvalue = parseInt(autoleads.AutoDialSkipcount)
    const dialer_takevalue = parseInt(autoleads.AutoDialTakecount)
    dispatch(AutoDialStatus(false));
    dispatch(AutoDialSkipcount(dialer_skipvalue+dialer_takevalue))
    setSkipValue(skipValue+parseInt(dialer_takevalue))
    //localStorage.setItem("dialer_skipvalue",skipValue+parseInt(dialer_takevalue))
     handleSearchClick(autoleads.AutoDialPagecount + 1);
    //handleSearchClick(skipValue+parseInt(dialer_takevalue))

    localStorage.setItem("setplaybtnflag", false);
  };

  const initiateCall = async (payload_data) => {
    try {
      
      const payload = {
        phone_number: payload_data.phone_number,
        caller_name:payload_data.name,
        custdata: {
          lead_id: payload_data.id,
          module: "leads",
        },
      };
      // const payload = {
      //   phone_number: "9042136400"
      // };

      const callresponse = await dispatch(MakeManualcall(payload));

      if (callresponse.status === 200) {
        localStorage.setItem("callstatus", JSON.stringify(callresponse.status));
      } else {
        toast.error("Call Not Initiated! Check Agent Status");
      }
    } catch (err) {
      if (err.response && err.response.status === 401) {
        localStorage.clear();
        window.location.href = `/`;
      } else {
        toast.error("Call Not Initiated! Check Agent Status");
      }
      localStorage.setItem(
        "callstatus",
        JSON.stringify(err.response?.status || "error")
      );
    }
  };

  const handleinputchange = (event) => {
    const value = event.target.value;
    
    dispatch(AutoDialTakecount(value))
    if (value === "0") {
      toast.error("Value cannot be 0.");
      return;  // Don't update the input value if it's 0
    }
    if (value <= 100) {
    let resetForm = JSON.parse(localStorage.getItem("resetForm"));
    let filterForm = {
      ...resetForm,
      limit:value
    }
    localStorage.setItem("resetForm",JSON.stringify(filterForm));
      setInputValue(value);
    } else {
      toast.error("Maximum limit of 100 Lead reached.");
    }
  };

  const handlePlayClick = async () => {
    try {
      if (backgroundstate.liveReadyAgentstatus) {
        localStorage.setItem("setplaybtnflag", true);
         
        if (autoleads.autodialstatus == false) {
          setIsPlayClicked(true);
          action("auto", true);
          dispatch(AutoDialbtn(true));
          dispatch(AutoDialStatus(true));
          let payload = {
            phone_number: autoleads.autodialeads[0].customerNumber,
            id: autoleads.autodialeads[0].customerID,
            module: "leads",
            name: autoleads.autodialeads[0].customerName,
          };
          dispatch(AutoDialCurrent(payload));
          let customer_info = {
            customer_name: autoleads.autodialeads[0].customerName,
            customer_number: autoleads.autodialeads[0].customerNumber,
            module: leadsData[0].module,
            customer_id: autoleads.autodialeads[0].customerID,
            call_type: "Outbound",
            type: "AUTO",
            call_outcome: "auto",
            lead_view:customviewvalue[0].label
          };

          dispatch(setcutomerinfo(customer_info));

          // localStorage.setItem("customer_info", JSON.stringify(customer_info));
          localStorage.setItem("customer_info", JSON.stringify(customer_info));

          await initiateCall(payload);
        } else {
          setautodialtimerstatus(!autodialtimerstatus);
          dispatch(AutoDialbtn(true));
        }
      } else {
        toast.error("Agent Not Ready");
      }
    } catch (error) {
      console.error("Error in handlePlayClick:", error);
    }
  };

  const handlePauseClick = async () => {
    try {
      setautodialtimerstatus(false);
      setIsPlayClicked(false);
      dispatch(AutoDialbtn(false));
    } catch (error) {
      return error;
    }
  };

  const handleResetClick = async (e) => {
    try {
      dispatch(AutoDialPagecount(0));
      dispatch(AutoDialSkipcount(0));
      dispatch(AutoDialTakecount(50))
      
      localStorage.setItem("setplaybtnflag", false);
      setautodialtimerstatus(false);
      setIsPlayClicked(false);
      dispatch(AutoDialbtn(false));
      dispatch(AutoDialStatus(false));
      setIsPlayClicked(false);
      action("auto", false);
      dispatch(Autolead("Allpop"));
      setLeadsData([]);
      setSelectedLeads([]);
      dispatch(AutoDialCurrent({}));
      localStorage.removeItem("diallerlead_status");
      localStorage.removeItem("diallercall_status");
      if(e !== undefined){
        localStorage.removeItem("resetForm");
        localStorage.removeItem("dialer_type");
        reset();
      }

    } catch (error) {
      return error;
    }
  };

  const handleLeadClick = () => {
    setSelectedCategory("leads");
  };

  const handleCheckboxChange = async (item, category, event, type) => {
     if (category === "leads") {
    
      if (type === "one") {
        const { checked } = event.target;
     
     
        let leads = [];
        if (checked) {
          
          
          await setSelectedLeads((prevSelectedLeads) => {
            leads = [...prevSelectedLeads, item];
            return [...prevSelectedLeads, item];
          });
        } else {
          
          
          await setSelectedLeads((prevSelectedLeads) => {
            
            let filterdata = prevSelectedLeads.filter(
              (selectedLead) => selectedLead.customerID !== item.customerID
            );
           
            
            leads = filterdata;
            return filterdata;
          });

        }
        dispatch(Autolead("push", leads));
      } else if (type === "all") {
        if (selectedLeads.length === leadsData.length) {
          setSelectedLeads([]);
          dispatch(Autolead("push", []));
        } else {
          setSelectedLeads(leadsData);
          dispatch(Autolead("push", leadsData));
        }
      }
    }
  };
  const handleentityvaluedropdown = (event) => {
    let filterForm = {
      entitytype:event
    }
    localStorage.setItem("resetForm",JSON.stringify(filterForm));
   
    
    localStorage.setItem('dialer_type',event.value)
    setEntitytypevalue(event);
    setFormValue()
  };
  const handlecustomviewdropdown = (event) => {
    let resetForm = JSON.parse(localStorage.getItem("resetForm"));

    let filterForm ={
      ...resetForm,
      customView:event
    }
    localStorage.setItem("resetForm",JSON.stringify(filterForm));
    setCustomviewvalue_list(event);
    
    setCustomviewValue([event]);
    // localStorage.set
  };

  const handleleadstatusvaluedropdown = (event) => {
    if (event.value !== localStorage.getItem("diallerlead_status")) {
      setPagecountflag(true);
    }
    let resetForm = JSON.parse(localStorage.getItem("resetForm"));
    let filterForm = {
      ...resetForm,
      leadStatus:event
    }
    localStorage.setItem("resetForm",JSON.stringify(filterForm));

    setLstatusselectvalue_slt(event);
    setLstatusselectvalue([event.value]);
    dispatch(AutoDialSubfilter1([event.value]));

    localStorage.setItem("diallerlead_status", [event.value]);
  };

  const handlecallstatusvaluedropdown = (event) => {
    let resetForm = JSON.parse(localStorage.getItem("resetForm"));
    let filterForm = {
      ...resetForm,
      callstatus:event
    }
    localStorage.setItem("resetForm",JSON.stringify(filterForm));

    setCallstatusselectvalue([event.value]);
    setCallstatusselectvalue_slt(event);
    dispatch(AutoDialSubfilter2([event.value]));
    localStorage.setItem("diallercall_status", [event.value]);
  };

  return (
    <div>
    
      {autodialwebphone ? (
        <Webphone />
      ) : (
        <>
          <div className="dropdown-content-container">
            <div className="auto-module-dropdown">
              <Select
                isMulti={false}
                isDisabled={setplaybtn_flag ? true : false}
                //  styles={{ zIndex: 0}}
                menuPortalTarget={document.body}
                menuShouldScrollIntoView={false}
                //menuPlacement={`auto`}
                isSearchable={true}
                options={entitytype}
                className="automodule-multi-select"
                classNamePrefix="select"
                placeholder="Select Module"
                value={entitytypevalue}
                onChange={handleentityvaluedropdown}
              />
            </div>

            <div className="dialler-data-count-outline">
              <input
                type="text"
                className="dialler-data-count-inline"
                value={inputValue}
                placeholder={inputValue}
                disabled={setplaybtn_flag}
                onChange={handleinputchange}
              ></input>
            </div>

            {entitytypevalue !== null && (
              <div>
                {entitytypevalue.value == "custom-view" ? (
                  <div>
                    <div>
                      <div className="rowview">
                        <div className="customview_dropdown">
                          <Select
                            isMulti={false}
                            isDisabled={setplaybtn_flag ? true : false}
                            menuPortalTarget={document.body}
                            menuShouldScrollIntoView={false}
                            isSearchable={true}
                            options={customviewoption}
                            className="automodule-multi-select"
                            classNamePrefix="select"
                            placeholder="Select CustomView"
                            value={customviewvalue_list}
                            onChange={handlecustomviewdropdown}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div>
                    <div>
                      <div className="rowview">
                        <div className="l_statusdropdown">
                          { dialer_subFilter1 && <Select
                            isMulti={false}
                            isDisabled={setplaybtn_flag ? true : false}
                            menuPortalTarget={document.body}
                            menuShouldScrollIntoView={false}
                            isSearchable={true}
                            options={leadstatus}
                            className="automodule-multi-select"
                            classNamePrefix="select"
                            placeholder="Select LeadStatus"
                            value={lstatusselctvalue_slt}
                            onChange={handleleadstatusvaluedropdown}
                          />}

                          <div> </div>
                          {dialer_subFilter2 && !crm_call_purpose && <Select
                            isMulti={false}
                            isDisabled={setplaybtn_flag ? true : false}
                            styles={{
                              menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                            }}
                            menuPortalTarget={document.body}
                            menuShouldScrollIntoView={true}
                            menuPlacement={`auto`}
                            isSearchable={true}
                            className="automodule-multi-select"
                            classNamePrefix="select"
                            placeholder="Select CallStatus"
                            options={callstatus}
                            value={callstatusselctvalue_slt}
                            onChange={handlecallstatusvaluedropdown}
                          />}
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>

          <div className="auto-placeeholder-container">
            <div
              className="auto-leadpholder-container"
              onClick={handleLeadClick}
            >
              <div className="auto-leadpholder">
                <input
                  type="checkbox"
                  checked={
                    selectedLeads.length > 0 &&
                    selectedLeads.length === leadsData.length
                  }
                  onChange={(event) => {
                    handleCheckboxChange(undefined, "leads", undefined, "all");
                  }}
                  className={
                    setplaybtn_flag
                      ? "disablebtn auto-inside-placeholder"
                      : "auto-inside-placeholder"
                  }
                />
                Leads
              </div>

              <div
                className={
                  selectedLeads.length > 0
                    ? "icon-container"
                    : "icon-container disablebtn"
                }
              >
                {isPlayClicked ? (
                  <FontAwesomeIcon
                    icon={faPause}
                    className="play-icon"
                    onClick={handlePauseClick}
                    style={{ color: "#2196F3", fontSize: "26px" }}
                  />
                ) : (
                  <FontAwesomeIcon
                    icon={faPlayCircle}
                    className="start-icon"
                    onClick={handlePlayClick}
                    style={{ color: "#2196F3", fontSize: "28px" }}
                  />
                )}
              </div>

              <div
                className={
                  lstatusselctvalue.length === 0 &&
                  callstatusselctvalue.length === 0 &&
                  customviewvalue.length === 0
                    ? "auto-serchicon disablebtn"
                    : "auto-serchicon"
                }
              >
                {((lstatusselctvalue.length !== 0 || callstatusselctvalue.length !== 0 || customviewvalue.length !==0)&& !autoleads.autodialstatus )&& (
                  <FontAwesomeIcon
                    icon={faSearch}
                    className="search-icon"
                    style={{
                      color: "#2196F3",
                      fontSize: "25px",
                      marginLeft: "5px",
                      cursor:"pointer"
                    }}
                    // onClick={handleSearchClick}
                    onClick={() =>
                      handleSearchClick(skipValue)
                    }
                  />
                )}
              </div>

              <div>
                {autoleads.autodialstatus && (
                  <p
                    style={{ color: "#E43554" }}
                    className="lead-countautodial"
                  >
                    {autodialtimer}
                  </p>
                )}
              </div>

              {autoleads.autodialstatus && (
                <div className="auto-reseticon">
                  <FontAwesomeIcon
                    icon={faCircleStop}
                    className="play-icon"
                    onClick={handleResetClick}
                    style={{ color: "#2196F3", fontSize: "28px" }}
                  />
                </div>
              )}
            </div>
          </div>

          <div className="auto-leadskipdata-listcontainer">
            {selectedCategory === "leads" && (
              <div>
                {/* {loader && (
                    <div className='leaddata-loader'>
                      <CircularProgress />
                    </div>

                  )} */}

                {/* Auto Dial leadlist container */}

                <div className="auto-list-container">
                  {leadsData.length == 0 &&
                  setplaybtn_flag == false &&
                  !loader ? (
                    <div>
                      <div>
                        <img className="dialler-nodata" src={nodataimg}></img>{" "}
                      </div>
                      <div className="no-data-found">No Data Found</div>
                    </div>
                  ) : setplaybtn_flag == true && selectedLeads.length == 0 ? (
                    <div>
                      <img
                        className="dialler-waiting"
                        src={diallerwaitingimg}
                      ></img>

                      <div className="additional-content">
                        <div className="more-data-text">
                          {" "}
                          Do you want to continue Dialling ?
                        </div>
                        <div className="dialler-yes-no-buttoncontainer">
                          <button
                            className="no-button"
                            onClick={handleResetClick}
                          >
                            <FontAwesomeIcon icon={faBan} /> No
                          </button>

                          <button
                            className="yes-button"
                            onClick={diallercontinue}
                          >
                            <FontAwesomeIcon icon={faThumbsUp} /> Yes
                          </button>
                        </div>
                      </div>
                    </div>
                  ) : loader ? (
                    <div>
                      <img
                        className="dialler-dooctiloader"
                        src={dooctiloader}
                      ></img>{" "}
                    </div>
                  ) : (
                    leadsData.map(
                      (lead, index) =>
                        lead.customerID && (
                          <div className="auto-leadlist" key={index}>
                            <div className="dialler-checkbox">
                              <input
                                className={setplaybtn_flag ? "disablebtn" : ""}
                                type="checkbox"
                                value={lead}
                                checked={selectedLeads.some(
                                  (selectedLead) =>
                                    selectedLead.customerID === lead.customerID
                                )}
                                onChange={(event) =>
                                  handleCheckboxChange(
                                    lead,
                                    "leads",
                                    event,
                                    "one"
                                  )
                                }
                              />
                            </div>

                            <div>
                              <img
                                src={person}
                                alt={lead.customerID}
                                className="dialler-contact-image"
                              />
                            </div>

                            <div className="dialler-list-view">
                              {lead.customerName}
                              <div> {lead.customerNumber}</div>
                              {/* <div className='dialler-lead-status'>
                                  {lead.status}
                                </div> */}
                            </div>
                          </div>
                        )
                    )
                  )}
                </div>
              </div>
            )}
          </div>
        </>
      )}

      {/* <BottomNavigator /> */}
    </div>
  );
}
export default Autodial;
