import { toast } from "react-toastify";

import { jwtDecode } from "jwt-decode";
import { useNavigate } from "react-router-dom";
import localdomain from "../../utils/constant.js";
import api from "../../utils/axios.js";
const auth0Config = localdomain();
const common_api_url = auth0Config.common_api_url;

export const login = (username, password) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      fetch(`${common_api_url}/v2/oauth/login`, {
        method: "POST",
        body: JSON.stringify({ username, password, role: 2 }),
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((response) => {
          if (response.status === 200) {
            return response.json();
          } else {
            reject("Login failed. Please check your credentials.");
          }
        })
        .then((data) => {
          const additionalData = {
            token: data.access_token,
            status: data.statusCode,
          };

          const access_token = data.access_token;
          const decoded = jwtDecode(access_token);
          let apinfo = {
            access_token: access_token,
            tenant_code: `${decoded["http://api.doocti.com/claims/tenant_code"]}`,
            api_url: `https://${decoded["http://api.doocti.com/claims/api_domain"]}`,
            dialer_url: `https://${decoded["http://api.doocti.com/claims/dialer_domain"]}`,
            portal_url: `https://${decoded["http://api.doocti.com/claims/portal_domain"]}`,
            token: data.access_token,
            socket_url: `https://${
              decoded[`http://api.doocti.com/claims/socket_domain`]
            }`,
            AuthUser: `${decoded[`aud`][1]}`,
          };
          api.defaults.baseURL = apinfo.dialer_url;
          //  api.defaults.baseURL = 'http://localhost:3001';
          localStorage.setItem("loginpage1", true);
          localStorage.setItem("apinfo", JSON.stringify(apinfo));
          localStorage.setItem("token", JSON.stringify(data));
          // localStorage.setItem('login', true);

          resolve(additionalData); // Resolve the Promise with additional data
        })
        .catch((error) => {
          toast.error("Login Failed!");
          console.error("Login error:", error);

          reject(error); // Reject the Promise to indicate a login error
        });
    });
  };
};

export const AsterConfig = (username, doocti_tenent) => {
  return (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
      // const apiToken = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJjbGllbnQiOiJhdGxhbnRvc29mdCIsInVzZXJuYW1lIjoiYXRsYW50b3NvZnRfYWRtaW4iLCJzY29wZSI6ImFkbWluIiwiaXNzIjoiaHR0cHM6Ly9saXZlLWRvb2N0aS5hdS5hdXRoMC5jb20vIiwic3ViIjoiejZMMzlyekRhZmtub0tucjVWUHloMU8zRW9aVUxGUGRAY2xpZW50cyIsImF1ZCI6Imh0dHBzOi8vcHJvZC1hcGkuZG9vY3RpLmNvbSIsImV4cCI6MTY2ODg3Mzk2ODk3MiwiaWF0IjoxNjA1ODAxOTY4fQ.f_TV2TheSK7ikg92MM91h6QN29BlUvxvMivr9fZcFKk";
      const apiToken = localStorage.getItem("apinfo");
      const apitoken = JSON.parse(apiToken);
      const configaccesstoken = apitoken.token;

      const headers = {
        Authorization: `Bearer ${configaccesstoken}`, // Add the bearer token here
      };

      await api
        .get(
          `${common_api_url}/common/project/config?tenant_code=${doocti_tenent}&user_email=${username}`,
          { headers }
        )
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };
};

export const LoginUserinfo = (payload) => {
  return (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
      await api
        .post(`/crm/login`, payload)
        .then((response) => {
          if (response.status === 200) {
            //  window.location.href='/homepage';

            localStorage.setItem("login", true);
            localStorage.setItem("loginpage1", false);
            localStorage.setItem("agentstatus", true);
            // toast.success('Login successful!');
          }
          resolve(response);
        })
        .catch((err) => {
          if (err.response.data.statusCode == 409) {
            toast.error("User Already Exist");
          } else {
            toast.error("Login Failed");
          }

          return err;
        });
    });
  };
};

export const Hangup = (payload) => {
  return (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
      await api
        .post(`/api/crm/dispose`, payload)
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };
};

export const Resume = (payload) => {
  return (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
      const apiToken = localStorage.getItem("apinfo");
      const apitoken = JSON.parse(apiToken);
      const configaccesstoken = apitoken.token;

      const headers = {
        Authorization: `Bearer ${configaccesstoken}`, // Add the bearer token here
      };

      await api
        .post(`/api/v2/resume`, payload, { headers })
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };
};

export const Pause = (payload) => {
  try {
    return (dispatch, getState) => {
      return new Promise(async (resolve, reject) => {
        const apiToken = localStorage.getItem("apinfo");
        const apitoken = JSON.parse(apiToken);
        const configaccesstoken = apitoken.token;

        const headers = {
          Authorization: `Bearer ${configaccesstoken}`, // Add the bearer token here
        };

        await api
          .post(`/api/v2/pause`, payload, { headers })
          .then((response) => {
            resolve(response);
          })
          .catch((err) => {
            reject(err);
          });
      });
    };
  } catch (error) {
    return error;
  }
};

export const Logout = (payload) => {
  return (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
      try {
        const apiToken = localStorage.getItem("apinfo");
        const apitoken = JSON.parse(apiToken);
        const configaccesstoken = apitoken.token;

        const headers = {
          Authorization: `Bearer ${configaccesstoken}`, // Add the bearer token here
        };
        await api
          .post(`/crm/logout`, payload, { headers })
          .then((response) => {
            if (response.status == 200) {
              // localStorage.removeItem("Showautodialpageui");
              // localStorage.removeItem("liveagentdata");
              // localStorage.removeItem("login");
              // localStorage.removeItem("loginpage1");
              // localStorage.removeItem("hanguppayload");
              // localStorage.removeItem("resume");
              // localStorage.removeItem("agentstatus");
              // localStorage.removeItem("config");
              // localStorage.removeItem("username");
              // localStorage.removeItem("apinfo");
              // localStorage.removeItem("token");
              // localStorage.removeItem("callstatus");
              // localStorage.removeItem("queuelogin");
              // localStorage.removeItem("crmuserinfo");
              // localStorage.removeItem("livecall");
              // localStorage.removeItem("entititytype");
              // localStorage.removeItem("station");
              // localStorage.removeItem("customer_info");
              // localStorage.removeItem("callerinfo");
              // localStorage.removeItem("livecall");
              // localStorage.removeItem("callstatus");
              // localStorage.removeItem("setplaybtnflag");
              
              // window.location.reload();
             
              
            }
            resolve(response);
          })
          .catch((err) => {
            reject(err);
          });
      } catch (error) {
        reject(error);
      }
    });
  };
};

export const FetchDispo = (condition) => {
  return () => {
    let query ="";
    if(condition){
      query += "?callresult=Call Result"
    }
    return new Promise(async (resolve, reject) => {
      await api
        .get(`/crm/dispostion${query}`)
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };
};

export const CreateTicket = (payload) => {
  return () => {
    return new Promise(async (resolve, reject) => {
      await api
        .get(
          `/fdk/crm/ticket/create?phone_number=${payload.phone_number}&agent_id=${payload.agent_id}&type=TicketCreate`
        )
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };
};

export const FetchTicket = (payload) => {
  return () => {
    return new Promise(async (resolve, reject) => {
      await api
        .get(
          `/fdk/crm/ticket/fetch?phone_number=${payload.phone_number}&type=${payload.type}&agent_id=${payload.agent_id}`
        )
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };
};

export const MakeManualcall = (payload) => {
  return () => {
    return new Promise(async (resolve, reject) => {
      const apinfo = JSON.parse(localStorage.getItem("apinfo"));
      const user_name = localStorage.getItem("username");
      const user_phone_number = JSON.parse(localStorage.getItem("config"))
        .projects[0].userInfo[0].user_phone_number;
      payload = {
        station: user_phone_number,
        campaign: "doocti",
        agent: {
          identity: "user",
          value: user_name,
        },

        ...payload,
      };
      await api
        .post(`/api/crm/call`, payload, {
          headers: {
            Authorization: `Bearer ${apinfo.token}`,
          },
        })
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          // reject(err);
        });
    });
  };
};

export const CrmUrl = (payload) => {
  return () => {
    return new Promise(async (resolve, reject) => {
      await api
        .get(
          `/api/crm/url?id=${
            payload !== undefined ? payload.customer_id : ""
          }&module=${payload !== undefined ? payload.module : ""}`
        )
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };
};

export const AsterOtherAgent = (payload) => {
  const apiToken = localStorage.getItem("apinfo");
  const apitoken = JSON.parse(apiToken);
  const configaccesstoken = apitoken.token;

  const headers = {
    Authorization: `Bearer ${configaccesstoken}`, // Add the bearer token here
  };
  return (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
      await api
        .post(`/otherAgentStatus`, payload,{headers})
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };
};

export const Transfercallapi = (payload) => {
  const apiToken = localStorage.getItem("apinfo");
  const apitoken = JSON.parse(apiToken);
  const configaccesstoken = apitoken.token;

  const headers = {
    Authorization: `Bearer ${configaccesstoken}`, // Add the bearer token here
  };
  return (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
      await api
        .post(`/api/crm/xfer`, payload , {headers})
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };
};
export const Conferencecallapi = (payload) => {
  const apiToken = localStorage.getItem("apinfo");
  const apitoken = JSON.parse(apiToken);
  const configaccesstoken = apitoken.token;

  const headers = {
    Authorization: `Bearer ${configaccesstoken}`, // Add the bearer token here
  };
  return (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
      await api
        .post(`/api/crm/bridge`, payload,{headers})
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };
};

export const DiallerLeadFetch = (payload) => {
  return () => {
    return new Promise(async (resolve, reject) => {
      await api
        .post(`/crm/lead/autodial`, payload)

        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };
};

export const DiallerCallStatus = (payload) => {
  return () => {
    return new Promise(async (resolve, reject) => {
      await api
        .get(`/crm/get/callstatus`)
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };
};

export const getLeadstatus = () => {
  return (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
      await api
        .get(`/crm/get/leadstatus`)
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };
};

export const UpdateLeadstatus = (data) => {
  return (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
      await api
        .put(`/crm/update/leadStatus`, data)
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };
};

export const LeadFetch = (params) => {
  return () => {
    return new Promise(async (resolve, reject) => {
      await api
        .get(`/crm/lead/autodial`, { params })
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };
};

export const CustomerInfo = (data) => {
  return () => {
    return new Promise(async (resolve, reject) => {
      await api
        .get(`/crm/ibcall/lead?phonenumber=${data.phone_number}`)
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };
};

export const AgentFetch = (payload) => {
  return () => {
    return new Promise(async (resolve, reject) => {
      const apiToken = localStorage.getItem("apinfo");
      const apitoken = JSON.parse(apiToken);
      const configaccesstoken = apitoken.token;

      const headers = {
        Authorization: `Bearer ${configaccesstoken}`, // Add the bearer token here
      };

      await api
        .get(`/realtime/liveAgents?agent=${payload.user}`, { headers })
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };
};

export const Createcrm = (payload) => {
  return () => {
    return new Promise(async (resolve, reject) => {
      await api
        .post(`/crm/create`, payload)
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };
};

export const FetchCallLog = (data) => {
  return () => {
    return new Promise(async (resolve, reject) => {
      await api
        .get(
          `/crm/calllog?agent=${data.agent}&skip=${data.skip}&take=${data.take}&phone_number=${data.phone_number}`
        )
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };
};

export const FetchDialerModule = (payload) => {
  return () => {
    return new Promise(async (resolve, reject) => {
      await api
        .get(`/api/crm/dialer/module`)
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };
};

export const FetchCustomview = (payload) => {
  return () => {
    return new Promise(async (resolve, reject) => {
      await api
        .get(`/api/crm/customview`)
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };
};
