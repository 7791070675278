// sessionReducer.js

const initialState = null; // Initial state

const sessionReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_SESSION':
        return action.payload; // Set the session value
    
    default:
      return state;
  }
};

export default sessionReducer;


